<template>
  <div class="bloc_content  user-identification-block">
      <h2>Utilisateurs rattachés au projet</h2>
	  <UserList 
	  id="bound-user-list"
	  ref="bound-user-list"
	  	:content="this.forms.customerForm.userIdentificationForm"
		btnLabel="Retirer"
		:btnColor="'danger'"
		:btnClick="removeUser.bind(this)"
		/>
	  
	  <h3>Rattacher un utilisateur au projet</h3>
	  <div class="user-search-form bloc_form">
		<label for="user_search_text">Recherche :</label>
		<div class="input-button-pair">
			<input type="text"   class="input_form form-control" name="user_search_text" id="user_search_text" v-model="userSearchText" @keyup.enter="searchForUsers()"/>
			<input type="submit" class="form-control btn-primary form-actions" value="Rafraichir" @click="searchForUsers()" />
		</div><!-- class="input-button-pair" -->
	  </div><!-- class="user-search-form bloc_form" -->
	  <div class="user-search-result bloc_form" 
	  	v-if="userSearchResponseReceived"
		:class="{'blink':blinkSearchResult}">
		
		<UserList :content="this.foundUsers"
			v-if="foundUsers.length > 0"
		btnLabel="Rattacher"
		:btnClick="addUser.bind(this)"
		btnColor="info"
		/>
		<div class="user-search-no-result" v-if="foundUsers.length == 0">Aucun utilisateur ne correspond</div>
	  </div><!-- class="user-search-result bloc_form"-->
	  <div class="user-search-result bloc_form" v-if="!userSearchResponseReceived">
		Aucune recherche effectuée
	  </div>
	  <h3>Créer un accès client</h3>
	  <div class="customer-create-form bloc_form">
		
		<div class="customer-create-field customer-login-field">
			<label for="new-user-email"  >Login/email</label>
			<div>
				<input type="text" 
					id="new-user-email" 
					v-model="newUser.email" 
					@input="emailChanged"
					class="input_form form-control"
					/>
				<div v-if="loginCheckStatus == 'ok'" class="login-msg login-ok text-success" ><i class="fa fa-check"></i> Login OK</div>
				<div v-if="loginCheckStatus == 'warn'" class="login-msg login-error text-danger" ><i class="fa fa-times"></i> Login déjà utilisé</div>
			</div>
		</div><!-- class="customer-create-field customer-login-field" -->
		
		<div class="customer-create-field customer-firstname-field">
			<label for="new-user-firstname" >Prénom</label>
			<input type="text" 
				id="new-user-firstname" 
				v-model="newUser.firstName" 
				class="input_form form-control"
			/>
		</div><!-- class="customer-create-field customer-firstname-field" -->
		
		<div class="customer-create-field customer-lastname-field">
			<label for="new-user-lastname" >Nom</label>
			<input type="text" 
				id="new-user-lastname" 
				v-model="newUser.lastName" 
				class="input_form form-control"
			/>
		</div><!-- class="customer-create-field customer-lastname-field" -->
		
		<div class="customer-create-field customer-password-field">
			<label for="new-user-password" >Mot de passe</label>
			<input type="text" 
				id="new-user-password"
				v-model="newUser.password"
				class="input_form form-control"
			 />
			 
		</div><!-- class="customer-create-field customer-password-field" -->
		
		<div class="customer-create-field customer-password-generator-field">
			<b-button class="form-actions" @click="generatePassword" variant="info" >Générer un mot de passe</b-button>
		</div><!-- class="customer-create-field customer-password-generator-field" -->
		
		<div class="customer-create-field customer-submit-field">
			<input type="submit" class="form-control btn-primary form-actions" value="Rajouter" @click="addNewUser" />
		</div>
	  </div><!-- class="customer-create-form bloc_form"> -->
</div><!-- class="bloc_content  user-identification-block" -->
</template>
<style>
.user-identification-block { 
	width:100%;
	h3 { 
		font-size:1.1rem; 
	} 
}

.input-button-pair {
	display: flex;
	flex-direction:row;
	gap:10px;
	align-items:stretch;
	input[type='text'] { flex-grow:1; }
	input[type='submit'] { 
		flex-shrink:1; 
		width:auto; 
		height:auto;
		border-radius:18px;
	}
}

.user-search-no-result {padding:10px;}

.user-search-result.blink {
  animation: blink-keyframes 1s ease-out 1;
}

@keyframes blink-keyframes {
	from { background-color: var(--bleu); }
 	to { background-color: white; }
}

.customer-create-form {
	display:grid;
	grid-template-columns:repeat(2,1fr)  ;
	gap:15px;
	.customer-login-field { grid-row-start:1; grid-column-start:1; grid-column-end: span 2 ;} 
	.customer-firstname-field { grid-row-start:2; grid-column-start:1; grid-column-end: span 1 ; }
	.customer-lastname-field  { grid-row-start:2; grid-column-start:2; grid-column-end: span 1 ; }
	.customer-password-field  { grid-row-start:3; grid-column-start:1; grid-column-end: span 1 ; }
	.customer-submit-field { grid-row-start:4; grid-column-start:2; grid-column-end: span 1 ; justify-self:end;} 
	.customer-password-generator-field  { grid-row-start:3; grid-column-start:2; grid-column-end: span 1 ; 
		button { height:50px; }
		align-self:end;
	}
}

input.form-actions, button.form-actions {
	border-radius: 18px;
	padding:10px;
	height: auto;
}


.login-msg {
	margin: 8px 0 0 10px;
}

.customer-submit-field {
	align-self: end;
}
</style>
<script>
import Vue from 'vue';
import { userService } from "@/services/userService" ;
import UserList from "./UserList.vue";
import utils  from '../utils/util.ts'

export default {
	components:{
		UserList
	},
  props: ['showWarning'],
    
  data() {
    return {
      show: true,
      isSpinning: false,
      generatedPassword: '',
	  userSearchText:'',
	  userSearchResponseReceived:false,
	  foundUsers:[],
	  blinkSearchResult:false,
	  /*
		loginCheckStatus (pour l'UI)
		3 status possibles 
		silent quand le champ login fait moins de 3 caractères pas de controles
		warn quand le champ login est déjà utilisé pour un autre utilisateur
		ok  quand le champ login est ok
	*/
	
	  loginCheckStatus:'silent',
	  newUser:{
		email:'',
		firstName:'',
		lastName:'',
		password:'',
	  }//newUser:{
    };
  },
  computed: {
    passwordPlaceholder() {
      return this.forms.customerForm.customerIdentityForm.city ? 'Changement de mot de passe' : 'Entrez le mot de passe';
    }
  },
  beforeMount(){
	this.indexUsers()
  },
  beforeUpdate(){
	this.indexUsers()
  },
  methods: {
	
    onSubmit(evt) {
      evt.preventDefault();
    },
	
    onReset(evt) {
      evt.preventDefault();
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
	
	indexUsers(){
		let list = this.forms.customerForm.userIdentificationForm
		//console.log("components/UserIdentification_form.vue:indexUsers()",list)
		let index = 0
		for(let user of list){
			user.index = ++index
		}
	},
	
    generateRandomPassword() {
      this.isSpinning = true;
      const length = 12;
      const charset = "abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789~!@#$%^&*()-_=+[]{};:,./?";
      let retVal = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
	  
      this.generatedPassword = retVal;
      setTimeout(() => {
        this.isSpinning = false;
        //this.forms.customerForm.userIdentificationForm.password = this.generatedPassword;
      }, 1000);
    },
	
	searchForUsers(){
		//console.log("components/UserIdentification_form.vue:searchForUsers()",this.userSearchText)
		let foundUsersPromise = userService.searchByText(this.userSearchText)
		foundUsersPromise.then((foundUsers)=>{
			//console.log("components/UserIdentification_form.vue:searchForUsers()",this.userSearchText,foundUsers)
			this.foundUsers = foundUsers
			this.userSearchResponseReceived = true
			
			this.blinkSearchResult = false
			this.$forceUpdate()
			setTimeout(()=>{
				this.blinkSearchResult = true
				this.$forceUpdate()
			},10)
		});//foundUsersPromise.then((foundUsers)=>{
		
		
  	},
	
	removeUser(user){
		let list = this.forms.customerForm.userIdentificationForm
		//console.log("components/UserIdentification_form.vue:removeUser()",user,list)
		list = list.filter((x)=>{
			//console.log("components/UserIdentification_form.vue:removeUser()",x,x.index,user.index,x.index !== user.index)
			return x.index !== user.index
		})
		this.forms.customerForm.userIdentificationForm = list
		//console.log("components/UserIdentification_form.vue:removeUser()",user,list)
		
	},
	
	addUser(userToAdd){
		let list = this.forms.customerForm.userIdentificationForm
		let index = list.length
		//console.log("components/UserIdentification_form.vue:removeUser()",this,this.blinkUser)
		for(let iterationUser of list){
			if(iterationUser.email === userToAdd.email){
				this.blinkUser(iterationUser)
				return;
			}
				
			if(iterationUser.index >= index)
				index = iterationUser.index+1
		}
		let duplicate = {... userToAdd}
		duplicate.index = index
		list.push(duplicate)
	},
	
	blinkUser(user){
		let boundUserListVueComponent = this.$refs['bound-user-list']
		boundUserListVueComponent.blinkUser(user)
		//console.log("components/UserIdentification_form.vue:blinkUser2()",this.$refs['bound-user-list']) 
		
	},
	
	emailChanged(event){
		let login = this.newUser.email
		if(login.length<=3)
			return
		
		//console.log("src/components/UserIdentification_form.vue:emailChanged()",login,userId)
		let checkLoginAvailablePromise = userService.checkLoginAvailable(login,null)
		checkLoginAvailablePromise.then((response)=>{
			
			//console.log("src/components/UserIdentification_form.vue:emailChanged() // promise.then",response)
			// response est un boolean car l'api retourne un boolean'
			if(response)
				this.loginCheckStatus = 'ok'
			else
				this.loginCheckStatus = 'warn'
			
		})//checkLoginAvailablePromise.then((response)=>{
		//console.log("components/UserIdentification_form.vue:emailChanged()",this.newUser.email,this.newUser,event,) 
	},
	
	addNewUser(){
		let newUser = { ... this.newUser }
		
		/*

		email:'',
		firstName:'',
		lastName:'',
		password:'',
		*/
		checkField(newUser.email,"Le champ login/email est vide")
		checkField(newUser.firstName,"Le champ prénom est vide")
		checkField(newUser.lastName,"Le champ nom est vide")
		checkField(newUser.password,"Le champ mot de passe est vide")
		
			
		//console.log("components/UserIdentification_form.vue:addNewUser()",newUser) 
		this.addUser(newUser)
		this.resetNewUser()
	},
	
	resetNewUser(){
		this.newUser.email = ''
		this.newUser.firstName = ''
		this.newUser.lastName = ''
		this.newUser.password = ''
	},
	generatePassword(){
		// TODO fetch customer's password rules from backend'
		let rule = {
			minLength:10,
			minLowerCase:2,
			minUpperCase:2,
			minDigits:2,
			minSpecials:0
		}
		let generated = utils.generatePassword(rule);
		this.newUser.password = generated
	}
  },// methods: ...
  
 
};//export default 

function checkField(field,message){
	if(field && field.trim().length > 0)
		return 
	
	Vue.toasted.error(message);
	throw message	
}
		//Vue.toasted.error("Le mot de passe est incorrect");
</script>

<template>
  <div class="app container-fluid">
    <div class="row">
      <div class="col-12">
        <Menu ref="top-menu"/>
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "./components/Menu";
import router from './router';
import {authenticationService} from '@/services/authentication';

export default {
  name: "App",
  components: {
    Menu
  },
  data() {
    return {
      tokenCheckInterval: null
    };
  },
  mounted() {
    this.startTokenCheck();
  },
  beforeDestroy() {
    this.clearTokenCheck();
  },
  methods: {
    startTokenCheck() {
      this.tokenCheckInterval = setInterval(() => {
        let logged = authenticationService.isLogged()
		let onLogginPage = router.currentRoute.name === 'login'
		
        if (!logged && !onLogginPage) {
	        router.push({ name: 'login' }).catch(err => {
	          if (err.name !== 'NavigationDuplicated') {
	            console.error("src/App.vue:startTokenCheck()",err);
	          }
	        });
        }// if (!logged && !onLogginPage) {
      }, 30000); // Check every 30 seconds // setInterval(() => {
    },
    clearTokenCheck() {
      if (this.tokenCheckInterval) {
        clearInterval(this.tokenCheckInterval);
        this.tokenCheckInterval = null;
      }
    }
  }
};
</script>


<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

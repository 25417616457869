import util from "../utils/util"

export default {
    updateCustomerForm
}

export function updateCustomerForm(response: any, forms: any, id: number) {
	//console.log("services/customer.ts:updateCustomerForm()",)
    forms.id = id
    forms.customerForm.customerIdentityForm = response.customerIdentity
	let electricalIdentity = {... response.electricalIdentity}
	if(Array.isArray(electricalIdentity.offPeakHours)){
		for(let oph of electricalIdentity.offPeakHours	){
			if(oph.startOffPeakHours){
				oph.startOffPeakHours = oph.startOffPeakHours.toString()
				oph.startOffPeakHours = oph.startOffPeakHours.substring(0,5)
			}
			if(oph.endOffPeakHours){
				oph.endOffPeakHours	= oph.endOffPeakHours.toString()
				oph.endOffPeakHours	= oph.endOffPeakHours.substring(0,5)
			}
		}
	}
    forms.customerForm.electricalIdentityForm = electricalIdentity
    forms.customerForm.projectParametersForm = response.projectParameters
    forms.customerForm.projectIdentityForm = response.projectIdentity
    forms.customerForm.userIdentificationForm = response.userList
    forms.simulationParametersSets = response.scenario.simulationParametersSets

    forms.customerForm.consumptionCurveForm = response.consumptionCurve

    forms.customerForm.simulationGoalsForm = response.simulationGoals

    forms.customerForm.scenariosForm = response.scenariosView
    forms.customerForm.simulationForm = response.simulation

    if (!forms.customerForm) {
        forms.customerForm = util.initCustomerForm(forms.customerForm)
    }
    
    if (!forms.customerForm.consumptionCurveForm) {
        forms.customerForm.consumptionCurveForm = util.initConsumptionCurveForm(forms.customerForm.consumptionCurveForm)
    }
    if (!forms.customerForm.simulationGoalsForm) {
        forms.customerForm.simulationGoalsForm = util.initSimulationGoalsForm(forms.customerForm.simulationGoalsForm)
    }

    if (!forms.customerForm.scenariosForm) {
        forms.customerForm.scenariosForm = util.initScenarioForm(forms.customerForm.scenariosForm)
    }

}

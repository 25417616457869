<template>
	<div v-if="hasText()"  class="pw-block" :class="cssClass()">
		<a href="#" @click.prevent="switchVisibilty()" class="btn btn-sm  btn-warning"> 
		<i v-if="!visible" class="fa fa-eye" aria-hidden="true"></i>
		<i v-if="visible" class="fa fa-eye-slash" aria-hidden="true"></i>
		</a>
		<div class="pw-content">{{getText()}}</div>
		
	</div>
</template>
<style scoped>

.pw-block {
	display:inline-flex;
	flex-direction:row;
	align-items:center;
	gap:15px;
	padding:5px;
	min-width:220px;
	border-radius:5px;
	background:var(--gris-clair);
}

.pw-content {
	
}

</style>
<script>
export default {

	props:	{
		clearPassword:{ 
			type:String, 
			required:false 
		},
	},//props:	{
	
	data(){
		return {
			visible:false
		};
	},//data(){
	
	mounted() {
	},// mounted() {
	
	
	
	methods : {
		cssClass(){
			return {
				'visible':this.visible,
				'masked':!this.visible
			}
		},//cssClass(){
			
		switchVisibilty(){
			//console.log("src/components/ClearPassword.vue:switchVisibilty()","hello")
			this.visible = !this.visible
		},//switchVisibilty(user){
			
		hasText(){
			if(!this.clearPassword)
				return false;
			return this.clearPassword.length > 0 ;
		},
		
		getText(){
			if(!this.hasText() )
				return ""
				
			if(this.visible)
				return this.clearPassword
			else
				return "\u{25CF}\u{25CF}\u{25CF}\u{25CF}\u{25CF}\u{25CF}\u{25CF}\u{25CF}\u{25CF}\u{25CF}" //Unicode Character 'BLACK CIRCLE' (U+25CF)
		},//getText(user){	
			
	}//methods : {
}
</script>
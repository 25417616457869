import {authenticationService} from '../services/authentication';


const axios = require('axios');


axios.defaults.withCredentials = true;

const instance = axios.create();

instance.interceptors.request.use(
    config => {
        if (!authenticationService.isLogged()) 
			return config;
        
		let token = authenticationService.status.jwtToken
        
		config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    },
    error => {
        console.error("src/middlewares/axios.ts:request-interceptor",'Error status:', error.response.status);
        return Promise.reject(error);
    }
);

export const axiosRequest = {
    postRequest: async (url, params) => {
        const response = await instance.post(url, params);
        return response.data;
    },
    getRequest: async (url,config={}) => {
        const response = await instance.get(url,config);
        return response.data;
    },
    putRequest: async (url, params) => {
        const response = await instance.put(url, params);
        return response.data;
    },
    deleteRequest: async (url, params) => {
        const response = await instance.delete(url, { data: params });
        return response.data;
    },
    uploadRequest: async (url, params) => {
        const response = await instance.put(url, params, { headers: {"Content-Type":"multipart/form-data"}});
        return response.data;
    }
};

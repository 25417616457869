import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Goal from '../views/Goal.vue'
import Information from '../views/Information.vue'
import Load_curve from '../views/Load_curve.vue'
import Scenario from '../views/Scenario.vue'
import Simulation from '../views/Simulation.vue'
import Single from '../views/Single.vue'
import Login from '../views/Login.vue'
import Users from '../views/Users.vue'
import {authenticationService} from '../services/authentication';
import UserUpdate from "@/views/UserUpdate.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    props: true,
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    props: true	,
	meta: { requiresUserOrMore: true }
  },
  {
    path: '/goal',
    name: 'goal',
    component: Goal,
    props: true,
    meta: { requiresUserOrMore: true }
  },
  {
    path: '/information',
    name: 'information',
    component: Information,
    props: true,
    meta: { requiresUserOrMore: true }
  },
  {
    path: '/load_curve',
    name: 'load_curve',
    component: Load_curve,
    props: true,
    meta: { requiresUserOrMore: true }
  },
  {
    path: '/scenario',
    name: 'scenario',
    component: Scenario,
    props: true,
    meta: { requiresUserOrMore: true }
  },
  {
    path: '/simulation',
    name: 'simulation',
    component: Simulation,
    props: true
  },
  {
    path: '/single',
    name: 'single',
    component: Single,
    props: true,
    meta: { requiresUserOrMore: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    props: true
  },
  {
    path: '/edit-user',
    name: 'edit-user',
    component: UserUpdate,
    props: true
  },
  {
    path: '/create-user',
    name: 'create-user',
    component: UserUpdate,
    props: true
  },
	{
	  path: '/my-profile',
	  name: 'my-profile',
	  component: UserUpdate,
	  props: true
	},
];

const router = new VueRouter({
  mode: 'history',
  routes
});

function isTokenExpired(expiry) {
  const now = Math.floor(Date.now() / 1000);
  return now >= expiry;
}

function logTokenExpiration(expiry) {
  const now = Math.floor(Date.now() / 1000);
  const timeLeft = Math.max(expiry - now, 0); // Prevent negative time left
  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;
}

router.beforeEach((to, from, next) => {
	if(!to.meta)
		return
	//console.log("src/router/index.tx:router.beforeEach()",to,authenticationService.status)
	if(to.meta.requiresAdmin){
		if(!authenticationService.isAdmin()){
			refuse(next,from,"Il faut être administrateur pour accéder à cette page.");
			return;
		}
	}
	
	if(to.meta.requiresUserOrMore){
		if(authenticationService.isCustomer()){
			refuse(next,from,"Les clients n'ont pas accès à cette page.");
			return;
		}
	}
	
	if (to.name !== 'login' && !authenticationService.isLogged()) {
		if (to.name !== 'home')
			Vue.toasted.info("Veuillez vous connecter pour accéder à cette page.");
	    next({ name: 'login' });
	    return;
	 }
	 
  next();
});

function refuse(next,from, message){
	Vue.toasted.error("Accès interdit : "+message);
  if (from.name) {
    next(false);
  } else {
    next({ name: 'home' });
  }
}

export default router;


import { axiosRequest } from '../middlewares/axios';
import { conf } from '../config/config';
import forms from '../constante/Forms';
import jwtDecode from 'jwt-decode';
import router from '../router';
import store from '../store';
import customerUtil from '../utils/customer';
import validation from '../utils/validation';
import util from '../utils/util';
import { customerService } from "@/services/customer";
import Vue from 'vue';



interface DecodedJWT {
    sub: string;
    role: string;
    exp: number;
    user_id: number;
    customer_id: number[];
}

class StatusProps {
		loggedUser : any|null = null ;
		connectionInProgress : boolean = false ;
	    jwtToken:  string|null = null ;
	    accountLockMsg: string|null = null ; 
		refreshTimeoutId: number|null = null ;
}

const status = new StatusProps();



export const authenticationService = {
	    login,
		logout,
	    status,
		refreshMenu,
		hasRole,
		isLogged,
		isAdmin,
		isUser,
		isCustomer,
		getClaim,
		getLogin,
		getUserId,
		getFirstname,
		getLastname,
		menuComponent:null,
		initialized:false,
		init,
		handleJwtToken,
		tokenRefresh
	};


	
function init(this:typeof authenticationService){
	if(this.initialized)
		return;
	
	let jwtToken = localStorage.getItem('jwtToken');
	if(jwtToken!=null){
		this.handleJwtToken(jwtToken)
	}
	//console.log('src/services/authntification.ts:init()',jwtToken)
	this.initialized = true
}

authenticationService.init()

function handleJwtToken(this:typeof authenticationService, jwtToken:string){
	let decoded = jwtDecode<DecodedJWT>(jwtToken);
	//console.log('src/services/authntification.ts:handleJwtToken()',decoded)
	
	this.status.loggedUser = decoded ;
    this.status.jwtToken = jwtToken;
    this.status.accountLockMsg = null ;
	if(this.status.refreshTimeoutId!==null){
		clearTimeout(this.status.refreshTimeoutId)
	}
	let now = Date.now()
	let exp = status.loggedUser.exp*1000 // passage en ms
	if(exp > now){
		let next = exp - 10*1000 // refresh 10 sec avant la fin
		let delay = (next-now)
		this.status.refreshTimeoutId = setTimeout(this.tokenRefresh.bind(this),delay)
	}
}

function logout(this:typeof authenticationService){
	this.status.loggedUser = null ;
	this.status.jwtToken = null ;
	localStorage.removeItem('jwtToken');
	window.location.reload();
}

async function login(this:typeof authenticationService, email, password) {
	if(status.connectionInProgress)
		return;
	
    try {
    	status.connectionInProgress = true
        let response = await axiosRequest.postRequest(`${conf.API_URL}/auth/submit`, {
            email,
            password
        });
    	status.connectionInProgress = false
        if (response.statusCode === 200) {
			let jwtToken = response.data.jwt ;
			this.handleJwtToken(jwtToken)
            localStorage.setItem('jwtToken', response.data.jwt);
			//console.log('src/services/authntification.ts:login()','logged',this.menuComponent)
			this.refreshMenu()
			await router.push('/');
            
        }

    } catch (error) {
    	status.connectionInProgress = false
    	//console.log("src/services/authentication.ts:login",error)
        if (error.response.status === 401 ) {
        	//console.log("src/services/authentication.ts:login",error.response.data)
            //console.log("src/services/authentication.ts:login",error)
            let loginInfo = error.response.data.data
            //console.log("src/services/authentication.ts:login",loginInfo)
            if(loginInfo //
            		&& loginInfo.accountLockStatus //
            		&& loginInfo.accountLockStatus.locked){
            	let accountLockStatus = loginInfo.accountLockStatus
            	let lockCauseMsg = `Suite à ${accountLockStatus.stepCount} tentatives de connexions échouées, `
            	let lockDurationMsg = ""
            	if(accountLockStatus.unit === 'YEARS'){
            		//blocage définitif
            		lockDurationMsg = "le compte est bloqué indéfiniment."
            	}else{
            		let unitTxt = util.javaChronoUnitToText(accountLockStatus.unit,accountLockStatus.ammount) ;
            		let lockEnd = new Date(accountLockStatus.end)
            		let lockEndDate = lockEnd.toLocaleDateString('fr-FR')
    				let lockEndTime = lockEnd.toLocaleTimeString('fr-FR')
            		lockDurationMsg = `le compte est bloqué pendant ${accountLockStatus.ammount} ${unitTxt}. `
            		lockDurationMsg += `Le blocage prendra fin le ${lockEndDate} à ${lockEndTime}`		
            	}
            	
            	let msg = lockCauseMsg+lockDurationMsg

                status.accountLockMsg = msg ;
            	//console.log("src/services/authentication.ts:login",msg)
            }else{
                status.accountLockMsg = null ;
            	Vue.toasted.error("Email ou mot de passe incorrect.");
            }
        }
        else if (error.response.status === 403) {
            const customMessage = error.response.data.exception.message;
            console.log("src/services/authentication.ts:login",customMessage);
            Vue.toasted.error("Vous n'avez pas les autorisations nécessaires");
        }else{
			const customMessage = error.response.data.exception.message;
            console.log("src/services/authentication.ts:login","Error from backend : ",customMessage);
            Vue.toasted.error("Erreur interne du serveur : "+error.response.status);
		}
		this.refreshMenu()
        return false;
    }
	
	
}//async function login(email, password) {

	
async function tokenRefresh(this:typeof authenticationService){
	let response = await axiosRequest.getRequest(`${conf.API_URL}/auth/renew`);
	let jwtToken = response.data.jwt ;
	this.handleJwtToken(jwtToken)
	localStorage.setItem('jwtToken', response.data.jwt);
	//console.log('src/services/authntification.ts:tokenRefresh()',response)
}//function tokenRefresh(){
	
function isLogged(this:typeof authenticationService){
	if(!status.loggedUser)
		return false;
	let expirationDate = status.loggedUser.exp * 1000
	//console.log('src/services/authntification.ts:isLogged()',status.loggedUser,expirationDate)
	return expirationDate > Date.now()
}
	
function hasRole(this:typeof authenticationService,roleToTest:string){
	
	if(!this.isLogged())
		return false ;
	let role = status.loggedUser.rn
	//console.log('src/services/authntification.ts:hasRole()',{roleToTest,role})
	if (typeof role === 'string' || role instanceof String){
		return role.toLowerCase() === roleToTest.toLowerCase()
	}
	return false 
}

function isAdmin(this:typeof authenticationService){
	return this.hasRole("admin")
}

function isUser(this:typeof authenticationService){
	return this.hasRole("user")
}

function isCustomer(this:typeof authenticationService){
	return this.hasRole("customer")
}

/*
 Les claims on des noms courts pour réduire la taille du JWT, pour avoir plus d'info
 cf. backend : /retix-back/src/main/java/fr/systemessolaires/retixback/security/JwtService.java
*/

function getClaim(this:typeof authenticationService,claimName:string){
	if(!this.isLogged())
		return null
	
	return status.loggedUser[claimName];
	
}

function getUserId(this:typeof authenticationService){
	return this.getClaim("uid")
}

function getLogin(this:typeof authenticationService){
	return this.getClaim("sub")
}

function getFirstname(this:typeof authenticationService){
	return this.getClaim("fn")
}

function getLastname(this:typeof authenticationService){
	return this.getClaim("ln")
}

	
function refreshMenu(this:typeof authenticationService){
	if(!this.menuComponent)
		return;
	//console.log('src/services/authntification.ts:refreshMenu()',this.menuComponent)
	//this.menuComponent.$forceUpdate()
}//function refreshMenu(){
	

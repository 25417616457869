import { axiosRequest } from '../middlewares/axios'
import {conf} from '../config/config'

export const userService = {
	search,
	searchByText,
	getSearchInfos,
    getUserFormInfo,
	checkLoginAvailable,
	submitUser,
	deleteUser
}

async function search(searchParams:any) {
  return await axiosRequest.postRequest(conf.API_URL + '/users/search', searchParams)
}

async function searchByText(searchText:string) {
  return await axiosRequest.postRequest(conf.API_URL + '/users/search', {'text':searchText})
}

async function getSearchInfos() {
  return await axiosRequest.getRequest(conf.API_URL + '/users/.user-search-infos')
}

async function getUserFormInfo(userId:number,mode:string) {
	let url = conf.API_URL + '/users/.user-form-info' ;
	let params = {mode:mode}
	if(userId)
		params['user_id']=userId;
	//console.log("src/service/getUserFormInfo()",userId,params)
    return await axiosRequest.getRequest(url,{params})
}

async function checkLoginAvailable(login:string, userId:number) {
	let url = conf.API_URL + '/users/check-login-available' ;
		let params = {login:login}
		if(userId)
			params['user_id']=userId;
		//console.log("src/service/getUserFormInfo()",userId,params)
	    return await axiosRequest.getRequest(url,{params})
}

async function submitUser(user:any) {


	let url = conf.API_URL + '/users'
	let isCreate = true
	if(user.id){
		isCreate = false
		url+='/'+user.id
	}
	
	if(isCreate)
		return axiosRequest.postRequest(url,user)
	else
		return axiosRequest.putRequest(url,user)
}

async function deleteUser(id) {
  return await axiosRequest.deleteRequest(conf.API_URL + '/users/'+id,{})
}
<template>
  <div>
    <LoadCurveSubPage03 v-if="uploading"/>
    <ModalError :message="errorMessage" :title="errorTitle"/>

    <div class="row">
      <div class="col-12">
        <div class="cta_prev_next">
          <a class="cta_prev" @click="goToWithoutCheck('/goal')">
            <span class="icon">
              <img
                  alt="Arrow prev"
                  border="0"
                  src="../assets/images/arrow_cta_prev.svg"
              />
            </span>
          </a>
        </div>

        <div class="content_retix">
          <div class="container-retix">
            <div class="row" v-if="!isCustomer()">
              <div class="col-12">
                <h1 v-if="!forms.customerForm.customerIdentityForm.companyName">
                  Nouveau client
                </h1>
                <h1 v-if="forms.customerForm.customerIdentityForm.companyName">
                  {{ forms.customerForm.customerIdentityForm.companyName }}
                </h1>
                <span class="step_customer">( Étape 4/4 )</span>
              </div>
            </div>
            <div class="row" v-if="!isCustomer()">
              <div class="col-12">
                <div class="timeline">
                  <div class="row">
                    <div
                        id="wizzard_informations"
                        class="col done"
                        @click="goTo('/information')"
                    >
                      <div class="dot_timeline">
                        <span class="minidot_timeline"></span>
                      </div>
                      <span class="txt_timeline"> Informations </span>
                    </div>
                    <div
                        id="wizzard_load"
                        class="col done"
                        @click="goTo('/load_curve')"
                    >
                      <div class="dot_timeline">
                        <span class="minidot_timeline"></span>
                      </div>
                      <span class="txt_timeline"> Courbe de charge </span>
                    </div>
                    <div
                        id="wizzard_goals"
                        class="col done"
                        @click="goTo('/goal')"
                    >
                      <div class="dot_timeline">
                        <span class="minidot_timeline"></span>
                      </div>
                      <span class="txt_timeline"> Objectifs </span>
                    </div>
                    <div
                        id="wizzard_scenarii"
                        class="col actif"
                        @click="goTo('/scenario')"
                    >
                      <div class="dot_timeline">
                        <span class="minidot_timeline"></span>
                      </div>
                      <span class="txt_timeline"> Scenarii </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content_customer">
              <ul class="row">
                <div class="col-12 scenario">
                  <div class="bloc_content">
                    <h2>
                      Scénario 01 : niveau d'auto-production selon couple (PV;
                      stockage)
                    </h2>
                    <!-- -->
                    <ul class="row content_scenario">
                      <li class="col-8">
                        <span class="legende_horizontal"
                        >Capacité de stockage en kWh</span
                        >
                        <span class="legende_vertical"
                        >Puissance PV en kWc</span
                        >

                        <table
                            v-if="
                            forms.customerForm.scenariosForm &&
                            forms.customerForm.scenariosForm.columns.length > 0 &&
                            forms.customerForm.scenariosForm.scenarios.length > 0 &&
                            forms.customerForm.scenariosForm.indexes.length > 0
                          "
                        >
                          <tr>
                            <td class="white_td"></td>
                            <td
                                v-for="column in forms.customerForm.scenariosForm.columns"
                                :key="column"
                                class="blue_td"
                            >
                              {{ column.toLocaleString() }}
                            </td>
                          </tr>

                          <tr
                              v-for="(line, indexLine) in forms.customerForm.scenariosForm
                              .scenarios[0].values"
                              :key="indexLine"
                          >
                            <td class="blue_td">
                              {{
                                forms.customerForm.scenariosForm.indexes[
                                    indexLine
                                    ].toLocaleString()
                              }}
                            </td>
                            <td
                                v-for="(value, indexCol) in line"
                                :key="indexCol"
                                class="classic_td"
                            >
                              <a
                                  :class="{
                                  actif:
                                    selection[0].line == indexLine &&
                                    selection[0].col == indexCol,
                                }"
                                  href="#/scenario"
                                  @click="selectedValue(0, indexLine, indexCol)"
                              >{{ value }}</a
                              >
                            </td>
                          </tr>
                        </table>
                      </li>
                      <li
                          v-if="typeof this.selection[0].line == 'undefined'"
                          class="col-4"
                      >
                        <span class="icon_info">
                          <img
                              alt="icon info"
                              src="../assets/images/icon_info.svg"
                          />
                        </span>
                        <span
                        >Cliquez sur une valeur du tableau pour choisir votre
                          cible de puissance de soutirage</span
                        >
                        <!-- -->
                      </li>
                      <li
                          v-if="typeof this.selection[0].line != 'undefined'"
                          class="col-4"
                      >
                        <span
                            v-if="
                            forms.customerForm.scenariosForm &&
                            forms.customerForm.scenariosForm.scenarios.length > 0 &&
                            forms.customerForm.scenariosForm.indexes.length > 0 &&
                            forms.customerForm.scenariosForm.columns.length > 0
                          "
                        >
                          Un taux d’auto-production de
                          <b
                          >{{
                              forms.customerForm.scenariosForm.scenarios[0].values[
                                  this.selection[0].line
                                  ][this.selection[0].col]
                            }}%</b
                          >
                          peut être atteint par une centrale de
                          <b
                          >{{
                              forms.customerForm.scenariosForm.indexes[
                                  this.selection[0].line
                                  ].toLocaleString()
                            }}
                            kWc</b
                          >
                          associée à une batterie de
                          <b
                          >{{
                              forms.customerForm.scenariosForm.columns[
                                  this.selection[0].col
                                  ].toLocaleString()
                            }}
                            kWh</b
                          >.
                        </span>
                        <!-- -->
                        <a
                            class="load_more margin_right_10"
                            style="cursor: pointer"
                            @click="gotoSimulation(0)"
                        >
                          <div class="load_more_mask">
                            <span class="txt">VOIR LA SIMULATION</span>
                            <span class="icon">
                              <svg
                                  style="enable-background: new 0 0 35.2 17.4"
                                  version="1.1"
                                  viewBox="0 0 35.2 17.4"
                                  x="0px"
                                  xml:space="preserve"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  y="0px"
                              >
                                <g>
                                  <g>
                                    <path
                                        class="st0"
                                        d="M27,17.4c-0.4,0.4-0.9,0.4-1.3,0c-0.3-0.3-0.3-0.9,0-1.3l6.4-6.4H0.9C0.4,9.8,0,9.4,0,8.9C0,8.4,0.4,8,0.9,8
          h31.2l-6.4-6.4c-0.3-0.4-0.3-0.9,0-1.3c0.4-0.4,0.9-0.4,1.3,0l8,8c0.3,0.3,0.3,0.9,0,1.3L27,17.4z M27,17.4"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <b-spinner v-if="uploading"/>
                          </div>
                        </a>
                      </li>
                    </ul>

                    <!-- -->
                  </div>
                </div>
              </ul>
              <!-- -->

              <ul class="row">
                <div class="col-12 scenario">
                  <div class="bloc_content">
                    <h2>
                      Scénario 02 : Puissance maximale soutirée selon couple (PV
                      ; Stockage)
                    </h2>
                    <!-- -->
                    <ul class="row content_scenario">
                      <li class="col-8">
                        <span class="legende_horizontal"
                        >Capacité de stockage en kWh</span
                        >
                        <span class="legende_vertical"
                        >Puissance PV en KWc</span
                        >
                        <table
                            v-if="
                            forms.customerForm.scenariosForm &&
                            forms.customerForm.scenariosForm.columns.length > 0 &&
                            forms.customerForm.scenariosForm.scenarios.length > 0 &&
                            forms.customerForm.scenariosForm.indexes.length > 0
                          "
                        >
                          <tr
                              v-if="
                              forms.customerForm.scenariosForm && forms.customerForm.scenariosForm.columns
                            "
                          >
                            <td class="white_td"></td>
                            <td
                                v-for="column in forms.customerForm.scenariosForm.columns"
                                :key="column"
                                class="blue_td"
                            >
                              {{ column.toLocaleString() }}
                            </td>
                          </tr>
                          <tr
                              v-for="(line, indexLine) in forms.customerForm.scenariosForm
                              .scenarios[1].values"
                              :key="indexLine"
                          >
                            <td class="blue_td">
                              {{
                                forms.customerForm.scenariosForm.indexes[
                                    indexLine
                                    ].toLocaleString()
                              }}
                            </td>
                            <td
                                v-for="(value, indexCol) in line"
                                :key="indexCol"
                                class="classic_td"
                            >
                              <a
                                  :class="{
                                  actif:
                                    selection[1].line == indexLine &&
                                    selection[1].col == indexCol,
                                }"
                                  href="#/scenario"
                                  @click="selectedValue(1, indexLine, indexCol)"
                              >{{ value }}</a
                              >
                            </td>
                          </tr>
                        </table>
                      </li>
                      <li
                          v-if="typeof this.selection[1].line == 'undefined'"
                          class="col-4"
                      >
                        <span class="icon_info">
                          <img
                              alt="icon info"
                              src="../assets/images/icon_info.svg"
                          />
                        </span>
                        <span
                        >Cliquez sur une valeur du tableau pour choisir votre
                          cible de puissance de soutirage</span
                        >
                        <!-- -->
                      </li>
                      <li
                          v-if="typeof this.selection[1].line != 'undefined'"
                          class="col-4"
                      >
                        <span
                            v-if="
                            forms.customerForm.scenariosForm &&
                            forms.customerForm.scenariosForm.scenarios.length > 0 &&
                            forms.customerForm.scenariosForm.indexes.length > 0 &&
                            forms.customerForm.scenariosForm.columns.length > 0
                          "
                        >
                          La puissance maximale soutirée est de
                          <b
                          >{{
                              forms.customerForm.scenariosForm.scenarios[1].values[
                                  this.selection[1].line
                                  ][this.selection[1].col]
                            }}kVA</b
                          >
                          avec une centrale de
                          <b
                          >{{
                              forms.customerForm.scenariosForm.indexes[
                                  this.selection[1].line
                                  ]
                            }}
                            kWc</b
                          >
                          associée à une batterie de
                          <b
                          >{{
                              forms.customerForm.scenariosForm.columns[this.selection[1].col]
                            }}
                            kWh</b
                          >.
                        </span>
                        <!-- -->
                        <a
                            class="load_more margin_right_10"
                            style="cursor: pointer"
                            @click="gotoSimulation(1)"
                        >
                          <div class="load_more_mask">
                            <span class="txt">VOIR LA SIMULATION</span>
                            <span class="icon">
                              <svg
                                  style="enable-background: new 0 0 35.2 17.4"
                                  version="1.1"
                                  viewBox="0 0 35.2 17.4"
                                  x="0px"
                                  xml:space="preserve"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  y="0px"
                              >
                                <g>
                                  <g>
                                    <path
                                        class="st0"
                                        d="M27,17.4c-0.4,0.4-0.9,0.4-1.3,0c-0.3-0.3-0.3-0.9,0-1.3l6.4-6.4H0.9C0.4,9.8,0,9.4,0,8.9C0,8.4,0.4,8,0.9,8
          h31.2l-6.4-6.4c-0.3-0.4-0.3-0.9,0-1.3c0.4-0.4,0.9-0.4,1.3,0l8,8c0.3,0.3,0.3,0.9,0,1.3L27,17.4z M27,17.4"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <b-spinner v-if="uploading"/>
                          </div>
                        </a>
                      </li>
                    </ul>

                    <!-- -->
                  </div>
                </div>
              </ul>

              <!-- -->

              <ul class="row">
                <div class="col-12 scenario">
                  <div class="bloc_content">
                    <h2>
                      Scénario 03 : pourcentage de réduction de la facture
                      annuelle (PV; stockage)
                    </h2>
                    <!-- -->
                    <ul class="row content_scenario">
                      <li class="col-8">
                        <span class="legende_horizontal"
                        >Capacité de stockage en kWh</span
                        >
                        <span class="legende_vertical"
                        >Puissance PV en KWc</span
                        >
                        <table
                            v-if="
                            forms.customerForm.scenariosForm &&
                            forms.customerForm.scenariosForm.columns.length > 0 &&
                            forms.customerForm.scenariosForm.scenarios.length > 0 &&
                            forms.customerForm.scenariosForm.indexes.length > 0
                          "
                        >
                          <tr>
                            <td class="white_td"></td>
                            <td
                                v-for="column in forms.customerForm.scenariosForm.columns"
                                :key="column"
                                class="blue_td"
                            >
                              {{ column.toLocaleString() }}
                            </td>
                          </tr>
                          <tr
                              v-for="(line, indexLine) in forms.customerForm.scenariosForm
                              .scenarios[2].values"
                              :key="indexLine"
                          >
                            <td class="blue_td">
                              {{
                                forms.customerForm.scenariosForm.indexes[
                                    indexLine
                                    ].toLocaleString()
                              }}
                            </td>
                            <td
                                v-for="(value, indexCol) in line"
                                :key="indexCol"
                                class="classic_td"
                            >
                              <a
                                  :class="{
                                  actif:
                                    selection[2].line == indexLine &&
                                    selection[2].col == indexCol,
                                }"
                                  href="#/scenario"
                                  @click="selectedValue(2, indexLine, indexCol)"
                              >{{ value }}</a
                              >
                            </td>
                          </tr>
                        </table>
                      </li>
                      <li
                          v-if="typeof this.selection[2].line == 'undefined'"
                          class="col-4"
                      >
                        <span class="icon_info">
                          <img
                              alt="icon info"
                              src="../assets/images/icon_info.svg"
                          />
                        </span>
                        <span
                        >Cliquez sur une valeur du tableau pour choisir votre
                          cible de puissance de soutirage</span
                        >
                        <!-- -->
                      </li>
                      <li
                          v-if="typeof this.selection[2].line != 'undefined'"
                          class="col-4"
                      >
                        <span
                            v-if="
                            forms.customerForm.scenariosForm &&
                            forms.customerForm.scenariosForm.scenarios.length > 0 &&
                            forms.customerForm.scenariosForm.indexes.length > 0 &&
                            forms.customerForm.scenariosForm.columns.length > 0
                          "
                        >
                          Un taux de réduction de
                          <b
                          >{{
                              forms.customerForm.scenariosForm.scenarios[2].values[
                                  this.selection[2].line
                                  ][this.selection[2].col]
                            }}%</b
                          >
                          peut être atteint par une centrale de
                          <b
                          >{{
                              forms.customerForm.scenariosForm.indexes[
                                  this.selection[2].line
                                  ]
                            }}
                            kWc</b
                          >
                          associée à une batterie de
                          <b
                          >{{
                              forms.customerForm.scenariosForm.columns[this.selection[2].col]
                            }}
                            kWh</b
                          >.
                        </span>
                        <!-- -->
                        <a
                            class="load_more margin_right_10"
                            style="cursor: pointer"
                            @click="gotoSimulation(2)"
                        >
                          <div class="load_more_mask">
                            <span class="txt">VOIR LA SIMULATION</span>
                            <span class="icon">
                              <svg
                                  style="enable-background: new 0 0 35.2 17.4"
                                  version="1.1"
                                  viewBox="0 0 35.2 17.4"
                                  x="0px"
                                  xml:space="preserve"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  y="0px"
                              >
                                <g>
                                  <g>
                                    <path
                                        class="st0"
                                        d="M27,17.4c-0.4,0.4-0.9,0.4-1.3,0c-0.3-0.3-0.3-0.9,0-1.3l6.4-6.4H0.9C0.4,9.8,0,9.4,0,8.9C0,8.4,0.4,8,0.9,8
          h31.2l-6.4-6.4c-0.3-0.4-0.3-0.9,0-1.3c0.4-0.4,0.9-0.4,1.3,0l8,8c0.3,0.3,0.3,0.9,0,1.3L27,17.4z M27,17.4"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <b-spinner v-if="uploading"/>
                          </div>
                        </a>
                      </li>
                    </ul>

                    <!-- -->
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -->
  </div>
</template>

<script>
import LoadCurveSubPage03 from "../components/LoadCurve_SubPage3";
import ModalError from "../components/ModalError";
import util from "../utils/util";
import customerUtil from "../utils/customer";
import validation from "../utils/validation";
import {authenticationService} from '../services/authentication';

export default {
  data() {
    return {
      selection: [
        {line: undefined, col: undefined, value: undefined},
        {line: undefined, col: undefined, value: undefined},
        {line: undefined, col: undefined, value: undefined},
      ],
      selectedTab: 0,
      uploading: false,
      errorMessage: "",
      errorTitle: "",
    };
  },
  components: {
    LoadCurveSubPage03,
    ModalError,
  },
  computed: {
   
  },
  mounted() {
    this.selectCustomer();
    this.updateWizard();
  },
  methods: {
    updateWizard: function () {
      document.getElementById("wizzard_informations").classList.remove("actif");
      document.getElementById("wizzard_goals").classList.remove("actif");
      document.getElementById("wizzard_load").classList.remove("actif");
      document.getElementById("wizzard_scenarii").classList.add("actif");
    },
	isAdmin(){
		return authenticationService.isAdmin()
	},//isAdmin(){
		
	isCustomer(){
		return authenticationService.isCustomer()
	},//isAdmin(){
	goTo: function (route) {
      if (
          route === "/load_curve" &&
          validation.isLoadCurveAnalyseValid(this.forms.customerForm.consumptionCurveForm)
      ) {
        this.$router.push(route);
      }
      if (
          route === "/scenario" &&
          forms.customerForm.scenariosForm &&
          forms.customerForm.scenariosForm.scenarios.length > 0
      ) {
        this.$router.push(route);
      }
      if (route === "/goal" && forms.customerForm.consumptionCurveForm) {
        this.$router.push(route);
      }
      if (route === "/information") {
        this.$router.push(route);
      }
    },
    async selectCustomer() {
      const id = window.localStorage.getItem("customerId")
          ? JSON.parse(window.localStorage.getItem("customerId"))
          : -1;
      if ((!forms.id || forms.id < 0) && id >= 0) {
        await this.$store.dispatch("customer/get", {id});
        const response = this.$store.getters["customer/get"];

        if (response.error) {
          this.errorTitle = "Get error";
          this.errorMessage = util.getErrorMessage(response.error);
          this.$bvModal.show("error-modal");
        } else {
          customerUtil.updateCustomerForm(response, forms, id);
        }
      }
    },
    selectedValue(indexTab, indexLine, indexCol) {
      this.selectedTab = indexTab;

      this.selection.forEach((elem, index) => {
        if (index == indexTab) {
          this.selection[index].line = indexLine;
          this.selection[index].col = indexCol;
          this.selection[index].value =
              forms.customerForm.scenariosForm.scenarios[index].values[indexLine][indexCol];
        } else {
          this.selection[index].line = undefined;
          this.selection[index].col = undefined;
          this.selection[index].value = undefined;
        }
      });
    },
    async gotoSimulation(indexTab) {
      this.uploading = true;
      forms.simulationParametersForm.installedPower =
          forms.customerForm.scenariosForm.indexes[this.selection[indexTab].line];
      forms.simulationParametersForm.batteryCapacity =
          forms.customerForm.scenariosForm.columns[this.selection[indexTab].col];
      forms.simulationParametersForm.maximumNetworkInjectionPower =
          forms.customerForm.projectParametersForm.maximumInjectionPower;
      forms.simulationParametersForm.batteryTriggerThreshold = 0;

      const objectToUpdate = {
        customerId: forms.id,
        installedPower: forms.simulationParametersForm.installedPower,
        batteryCapacity: forms.simulationParametersForm.batteryCapacity,
        maximumNetworkInjectionPower: forms.simulationParametersForm.maximumNetworkInjectionPower,
        batteryTriggerThreshold: forms.simulationParametersForm.batteryTriggerThreshold
      };

      await this.$store.dispatch("customer/getSimulation", objectToUpdate);
      const customerResponse = this.$store.getters["customer/getSimulation"];
      if (customerResponse.error) {
        //location.reload();
        this.errorTitle = "Simulation error";
        this.errorMessage = util.getErrorMessage(customerResponse.error);
        this.$bvModal.show("error-modal");
      } else {
        forms.customerForm.simulationForm = customerResponse.simulation;

        this.uploading = false;

        this.$router.push({
          name: "simulation",
          params: {
            slidderIndexesProp: {
              installedPowerIndex: this.selection[indexTab].line,
              batteryCapacityIndex: this.selection[indexTab].col,
              injectionPowerIndex: 9,
              batteryTriggerThresholdIndex: 0,
            },
          },
        });
      }
    },
      goToWithoutCheck: function (route) {
          if (route === "/load_curve" && forms.customerForm.consumptionCurveForm) {
              this.$router.push(route);
          }
          if (route === "/scenario" && forms.customerForm.scenariosForm) {
              this.$router.push(route);
          }
          if (route === "/goal" && forms.customerForm.consumptionCurveForm.annualConsumption) {
              this.$router.push(route);
          }
          if (route === "/information") {
              this.$router.push(route);
          }
      }
  },
};
</script>
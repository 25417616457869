const _ = require('lodash');
import { FormChecker } from './form-checker'

export default {
	checkInformation,
	isInformationValid,
	isPercentageValid,
	isDayMonthDateValid,
	isGoalValid,
	isPartialLoadCurveValid,
	isLoadCurveAnalyseValid,
};

function checkInformation(form: any):FormChecker{
	let checker = new FormChecker(form)
		
	checker.notBlank('customerIdentityForm.companyName',"Identité du client / Raison sociale")
	//checker.notEmpty('customerIdentityForm.logo',"Logo")
	checker.notBlank('customerIdentityForm.address',"Identité du client / Adresse (n° + rue)")
	checker.notBlank('customerIdentityForm.postalCode',"Identité du client / Code postal")
	checker.notBlank('customerIdentityForm.city',"Identité du client / Ville")
	checker.notBlank('customerIdentityForm.country',"Identité du client / Pays")
	
	checker.notBlank('projectIdentityForm.projectStatus',"Identité du projet / Statut du projet")
	checker.notBlank('projectIdentityForm.projectManager',"Identité du projet / Responsable du projet")
	
	checker.notBlank('electricalIdentityForm.subscription',"Identité électrique / Abonnement")
	checker.notBlank('electricalIdentityForm.subscribedPower',"Identité électrique / Puissance souscrite")
	
	
	let tarifUnique = checker.getValue("electricalIdentityForm.uniqueTariff")
	//console.log("utils/validation.ts:isInformationValid()",tarifUnique)
	if(tarifUnique){
		checker.notBlank('electricalIdentityForm.purchaseTariff',"Identité électrique / Tarif achat")
		
	}else{
		let offPeakHoursChecker = checker.arrayChecker("electricalIdentityForm.offPeakHours","Identité électrique / Heures creuses")
		offPeakHoursChecker.hasElements()
		offPeakHoursChecker.notBlank("startOffPeakHours","Début")
		offPeakHoursChecker.notBlank("endOffPeakHours","Fin")
		
		checker.notBlank('electricalIdentityForm.winterFullHoursTariff',"Identité électrique / Tarif HPH (Heures pleines hiver)")
		checker.notBlank('electricalIdentityForm.winterOffHoursTariff',"Identité électrique / Tarif HCH (Heures creuses hiver)")
		checker.notBlank('electricalIdentityForm.summerFullHoursTariff',"Identité électrique / Tarif HPE (Heures pleines été)")
		checker.notBlank('electricalIdentityForm.summerOffHoursTariff',"Identité électrique / Tarif HCE (Heures creuses été)")

		checker.notBlank('electricalIdentityForm.seasonReferentialFormatted.summerStartDate',"Identité électrique / Début HE (heures été)")
		checker.dayAndMonth('electricalIdentityForm.seasonReferentialFormatted.summerStartDate',"Identité électrique / Début HE (heures été)")
		checker.notBlank('electricalIdentityForm.seasonReferentialFormatted.winterStartDate',"Identité électrique / Début HH (heures hiver)")
		checker.dayAndMonth('electricalIdentityForm.seasonReferentialFormatted.winterStartDate',"Identité électrique / Début HH (heures hiver)")
		
	}
	
	checker.notBlank('electricalIdentityForm.energyTaxes',"Identité électrique / Turpe et taxe énergie")
	
	checker.notBlank('projectParametersForm.orientationReferential.id',"Paramètres du projet / Profil de production")
	checker.notBlank('projectParametersForm.minimumPowerPeak',"Paramètres du projet / Puissance crête minimale")
	checker.notBlank('projectParametersForm.maximumPowerPeak',"Paramètres du projet / Puissance crête maximale")
	checker.notBlank('projectParametersForm.maximumStorageCapacity',"Paramètres du projet / Capacité de stockage max")
	checker.notBlank('projectParametersForm.batteryPerformanceRate',"Paramètres du projet / Rendement des batteries")
	checker.isBoundNumber('projectParametersForm.batteryPerformanceRate',0,100,"Paramètres du projet / Rendement des batteries")
	checker.notBlank('projectParametersForm.maximumChargeDischargePower',"Paramètres du projet / Puissance de charge/décharge")
	checker.notBlank('projectParametersForm.maximumInjectionPower',"Paramètres du projet / Puissance d'injection max")
	checker.notBlank('projectParametersForm.producible',"Paramètres du projet / Productible")
	checker.notBlank('projectParametersForm.surplusValuationTariff',"Paramètres du projet / Tarif de valorisation du surplus")
	checker.notBlank('projectParametersForm.averageCarbonContentPerKWh',"Paramètres du projet / Contenu moyen carbone par kWh")
	checker.notBlank('projectParametersForm.weekStartDay',"Paramètres du projet / Journée type / Semaine")
	checker.notBlank('projectParametersForm.weekendStartDay',"Paramètres du projet / Journée type / Weekend")
	checker.notBlank('projectParametersForm.description',"Paramètres du projet / Description")
	return checker
}

function isInformationValid(form: any) {
	let checker = checkInformation(form)
	return checker.isValid()
	
}

function isPercentageValid(value: number) {
	let data = {'field':value}
	let checker = new FormChecker(data)
	return checker.isBoundNumber('field',0,100,"Field")
	//return value >= 0 && value <= 100;
}

function isDayMonthDateValid(value: string) {
	let data = {field:value}
	let checker = new FormChecker(data)
	return checker.dayAndMonth('field',"Field")
}

function isGoalValid(form: any) {
	let isValid = false;
	if ((form.minimumAutoProductionRate >= 0) && (form.maximumWithdrawPower >= 0) && (form.maximumAnnualNetworkBilling >= 0)) {
		isValid = true;
	}
	isValid = isValid && isPercentageValid(form.minimumAutoProductionRate);
	return isValid;
}

function isPartialLoadCurveValid(form: any) {
	return !form.months.some((month: any) => month.value == '');
}

function isLoadCurveAnalyseValid(form: any) {
	let isValid = false;

	if (
		form.annualConsumption &&
		form.fullHoursToOffPeakHoursRate &&
		form.averageWithdrawPower &&
		form.maximumWithdrawPower &&
		form.monthlyConsumptions &&
		form.monthlyConsumptions.length == 12 &&
		form.monthlyConsumptions.every((element: any) => element && element > 0)
	) {
		isValid = true;
	}

	return isValid;
}

function isImageValid(form: any) {
	let isValid = false;
	const maxSizeInBytes = 50 * 1024;

	if (form.logo.size < maxSizeInBytes) {
		isValid = true;
	}

	return isValid;
}

<template>
  <div>
    <div class="content_retix">
      <div class="container-retix">
        <div class="home">
          <div class="row">
            <div class="col-6">
              <h1>Accueil</h1>
            </div>
            <div class="col-6 align_right">
              <div class="search_retix">
                <input
                    type="search"
                    id="site-search"
                    name="search"
                    aria-label="Search through site content"
                    placeholder="Filtrer par Client et Description"
                    v-model="searchInput"
                />
                <button>Search</button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <ul class="ul_home">
                <!-- -->
                <li>
                  <div class="bloc_home_header">
                    <div class="row">
                      <div class="col-3 padding_right_10">
                        <a class="order_items" @click="sort('client')">
                          <span class="txt">Client</span>
                          <span class="icon">
                          <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 390.7 391.5"
                              style="enable-background:new 0 0 390.7 391.5;"
                              xml:space="preserve"
                          >
                            <g>
                              <g>
                                <path
                                    class="st0"
                                    d="M208.6,260.9c-6.6-5.8-17.4-5-23.3,1.7L123.7,332V54.5c0-9.1-7.5-16.6-16.6-16.6c-9.1,0-16.6,7.5-16.6,16.6
			V332l-61.6-69.4c-6.6-6.6-16.6-7.5-23.3-1.7c-6.6,6.6-7.5,16.6-1.7,23.3l90.7,101.5c6.8,8.4,18.8,7.1,24.2,0l91.5-101.5
			C216.1,277.6,215.3,266.7,208.6,260.9L208.6,260.9z M208.6,260.9"
                                />
                                <path
                                    class="st0"
                                    d="M386.8,107.8L296.8,6.2c-8-8.1-18-8.6-25,0l-91.5,101.5c-5.8,6.6-5,17.4,1.7,23.3c10.2,8.2,20.6,2.6,23.3-1.7
			l61.6-69.4v276.7c0,9.1,7.5,16.6,16.6,16.6c9.1,0,16.6-6.6,16.6-15.8V60l61.6,69.4c6.6,6.6,16.6,7.5,23.3,1.7
			C391.7,124.4,392.6,114.4,386.8,107.8L386.8,107.8z M386.8,107.8"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                        </a>
                      </div>
                      <div class="col-1 padding_right_10">
                        <a class="order_items" @click="sort('date')">
                          <span class="txt">Date</span>
                          <span class="icon">
                          <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 390.7 391.5"
                              style="enable-background:new 0 0 390.7 391.5;"
                              xml:space="preserve"
                          >
                            <g>
                              <g>
                                <path
                                    class="st0"
                                    d="M208.6,260.9c-6.6-5.8-17.4-5-23.3,1.7L123.7,332V54.5c0-9.1-7.5-16.6-16.6-16.6c-9.1,0-16.6,7.5-16.6,16.6
			V332l-61.6-69.4c-6.6-6.6-16.6-7.5-23.3-1.7c-6.6,6.6-7.5,16.6-1.7,23.3l90.7,101.5c6.8,8.4,18.8,7.1,24.2,0l91.5-101.5
			C216.1,277.6,215.3,266.7,208.6,260.9L208.6,260.9z M208.6,260.9"
                                />
                                <path
                                    class="st0"
                                    d="M386.8,107.8L296.8,6.2c-8-8.1-18-8.6-25,0l-91.5,101.5c-5.8,6.6-5,17.4,1.7,23.3c10.2,8.2,20.6,2.6,23.3-1.7
			l61.6-69.4v276.7c0,9.1,7.5,16.6,16.6,16.6c9.1,0,16.6-6.6,16.6-15.8V60l61.6,69.4c6.6,6.6,16.6,7.5,23.3,1.7
			C391.7,124.4,392.6,114.4,386.8,107.8L386.8,107.8z M386.8,107.8"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                        </a>
                      </div>

                      <div class="col-3 padding_right_10">Description</div>
                      <div class="col-2 padding_right_10">
                        <a class="order_items" @click="sort('manager')">
                          <span class="txt">Responsable</span>
                          <span class="icon">
                          <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 390.7 391.5"
                              style="enable-background:new 0 0 390.7 391.5;"
                              xml:space="preserve"
                          >
                            <g>
                              <g>
                                <path
                                    class="st0"
                                    d="M208.6,260.9c-6.6-5.8-17.4-5-23.3,1.7L123.7,332V54.5c0-9.1-7.5-16.6-16.6-16.6c-9.1,0-16.6,7.5-16.6,16.6
			V332l-61.6-69.4c-6.6-6.6-16.6-7.5-23.3-1.7c-6.6,6.6-7.5,16.6-1.7,23.3l90.7,101.5c6.8,8.4,18.8,7.1,24.2,0l91.5-101.5
			C216.1,277.6,215.3,266.7,208.6,260.9L208.6,260.9z M208.6,260.9"
                                />
                                <path
                                    class="st0"
                                    d="M386.8,107.8L296.8,6.2c-8-8.1-18-8.6-25,0l-91.5,101.5c-5.8,6.6-5,17.4,1.7,23.3c10.2,8.2,20.6,2.6,23.3-1.7
			l61.6-69.4v276.7c0,9.1,7.5,16.6,16.6,16.6c9.1,0,16.6-6.6,16.6-15.8V60l61.6,69.4c6.6,6.6,16.6,7.5,23.3,1.7
			C391.7,124.4,392.6,114.4,386.8,107.8L386.8,107.8z M386.8,107.8"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                        </a>
                      </div>
                      <div class="col-2 padding_right_10">
                        <a class="order_items" @click="sort('status')">
                          <span class="txt">Statut</span>
                          <span class="icon">
                          <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 390.7 391.5"
                              style="enable-background:new 0 0 390.7 391.5;"
                              xml:space="preserve"
                          >
                            <g>
                              <g>
                                <path
                                    class="st0"
                                    d="M208.6,260.9c-6.6-5.8-17.4-5-23.3,1.7L123.7,332V54.5c0-9.1-7.5-16.6-16.6-16.6c-9.1,0-16.6,7.5-16.6,16.6
			V332l-61.6-69.4c-6.6-6.6-16.6-7.5-23.3-1.7c-6.6,6.6-7.5,16.6-1.7,23.3l90.7,101.5c6.8,8.4,18.8,7.1,24.2,0l91.5-101.5
			C216.1,277.6,215.3,266.7,208.6,260.9L208.6,260.9z M208.6,260.9"
                                />
                                <path
                                    class="st0"
                                    d="M386.8,107.8L296.8,6.2c-8-8.1-18-8.6-25,0l-91.5,101.5c-5.8,6.6-5,17.4,1.7,23.3c10.2,8.2,20.6,2.6,23.3-1.7
			l61.6-69.4v276.7c0,9.1,7.5,16.6,16.6,16.6c9.1,0,16.6-6.6,16.6-15.8V60l61.6,69.4c6.6,6.6,16.6,7.5,23.3,1.7
			C391.7,124.4,392.6,114.4,386.8,107.8L386.8,107.8z M386.8,107.8"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                <li v-for="(cat, index) in sortedCats" :key="index">
                  <div class="bloc_home_content">
                    <div class="row">
                      <div class="col-1 padding_right_10">
                        <div class="logo_icone">
                          <b-img style="max-height:32px" :src="cat.logo" />
                        </div>
                      </div>
                      <div class="col-2 padding_right_10">{{cat.client}}</div>
                      <div class="col-1 padding_right_10">{{cat.date}}</div>
                      <div class="col-3 padding_right_10">{{cat.description}}</div>
                      <div class="col-2 padding_right_10">{{cat.manager}}</div>
                      <div class="col-1 padding_right_10">{{ getStatusLabel(cat.status) }}</div>
                      <div class="col-1 align_right" v-if="isAdmin()" @click="openDeletionConfirmation(cat.id)">
                        <a class="cta_bin">
                          <div class="cta_bin_mask">
                            <img src="../assets/images/trash-can-regular.svg" alt="bin cta" />
                          </div>
                        </a>
                      </div>
                      <div class="col-1 align_right remove-margin-left" @click="selectClient(cat.id, index)">
                        <a class="cta_arrow">
                          <div class="cta_arrow_mask">
                            <img v-if="!cat.isLoading" src="../assets/images/arrow_cta.svg" alt="arrow cta" />
                            <i v-else class="fa fa-spinner fa-spin" style="color: white;"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalError :message="errorMessage" :title="errorTitle" />
    <ModalDeleteConfirmation :message="errorMessage" :title="errorTitle" :id="customerId" />
  </div>
</template>

<style scoped>
td,
th {
  padding: 5px;
}
th {
  cursor: pointer;
}
.cta_arrow_mask {
	i.fa {
		z-index:2;
	}
}
</style>

<script>
import validation from "../utils/validation"
import util from "../utils/util"
import customerUtil from "../utils/customer"
import ModalError from "../components/ModalError"
import ModalDeleteConfirmation from "@/components/ModalDeleteConfirmation.vue";
import {authenticationService} from '../services/authentication';

export default {
  components: {
    ModalDeleteConfirmation,
    ModalError
  },
  data() {
    return {
      customers: [],
      originCats: [],
      cats: [],
      currentSort: "name",
      currentSortDir: "asc",
      searchInput: "",
      filters: [],
      errorMessage: "",
      errorTitle: "",
      customerId: 0,
      statusOptions: [
        { id: 1, value: 'GAGNE', label: 'Gagnée' },
        { id: 2, value: 'OUVERTE', label: 'Ouverte' },
        { id: 3, value: 'PERDUE', label: 'Perdue' },
        { id: 4, value: 'EN_RETARD', label: 'En retard' },
        { id: 5, value: 'ANNULE', label: 'Annulée' },
        { id: 6, value: 'FERME', label: 'Fermée' },
      ],
      selection: [
        {line: undefined, col: undefined, value: undefined},
        {line: undefined, col: undefined, value: undefined},
        {line: undefined, col: undefined, value: undefined},
      ],
      selectedTab: 0,
      uploading: false,
      isLoading: false,
    };//return {
  },//data() {
  methods: {
    async gotoSimulation() {
      this.uploading = true;
      forms.simulationParametersForm.installedPower = forms.simulationParametersSets.installedPowerSet[0];
      forms.simulationParametersForm.batteryCapacity = forms.simulationParametersSets.batteryCapacitySet[0];
      forms.simulationParametersForm.maximumNetworkInjectionPower = forms.simulationParametersSets.injectionPowerSet[9];
      forms.simulationParametersForm.batteryTriggerThreshold = forms.simulationParametersSets.batteryTriggerThresholdSet[0];
      const objectToUpdate = {
        customerId: forms.id,
        installedPower: forms.simulationParametersForm.installedPower,
        batteryCapacity: forms.simulationParametersForm.batteryCapacity,
        maximumNetworkInjectionPower: forms.simulationParametersForm.maximumNetworkInjectionPower,
        batteryTriggerThreshold: forms.simulationParametersForm.batteryTriggerThreshold
      };
      await this.$store.dispatch("customer/getSimulation", objectToUpdate);
      const customerResponse = this.$store.getters["customer/getSimulation"];
      if (customerResponse.error) {
        this.errorTitle = "Simulation error";
        this.errorMessage = util.getErrorMessage(customerResponse.error);
        this.$bvModal.show("error-modal");
      } else {
        forms.customerForm.simulationForm = customerResponse.simulation;

        this.uploading = false;

        this.$router.push({
          name: "simulation",
          params: {
            slidderIndexesProp: {
              installedPowerIndex: 0,
              batteryCapacityIndex: 0,
              injectionPowerIndex: 9,
              batteryTriggerThresholdIndex: 0,
            },
          },
        });
      }
    },// async gotoSimulation() {
    async selectClient(id, index) {
	  
	  let selectedClient = this.cats.find((candidate)=> candidate.id === id)
	  //console.log("views/Home.vue:selectClient()",id,{...selectedClient})
	  selectedClient.isLoading = true;
	  this.isLoading = true;
	  
      //this.cats[index].isLoading = true;
      await this.$store.dispatch('customer/get', {id})
      const response = this.$store.getters['customer/get']
      if (response.error) {
        this.errorTitle = "Get error"
        this.errorMessage = util.getErrorMessage(response.error)
        this.$bvModal.show('error-modal')
      } else {
        customerUtil.updateCustomerForm(response, forms, id)
        window.localStorage.setItem('customerId', id)
        let routeName;
		//console.log("src/components/Home.vue:selectClient()",{isCustomer:this.isCustomer()})
        if (!this.isCustomer()) {
          routeName = 'scenario'
          if (!validation.isInformationValid(forms.customerForm)) {
            routeName = 'information'
          } else if (!validation.isLoadCurveAnalyseValid(forms.customerForm.consumptionCurveForm)) {
            routeName = 'load_curve'
          } else if ((forms.customerForm.scenariosForm.scenarios[0].values[0][0] == null) || !validation.isGoalValid(forms.customerForm.simulationGoalsForm)) {
            routeName = 'goal'
          }
          this.$router.push({name: routeName})
        }
        else {
          await this.gotoSimulation();
        }
      }
      this.isLoading = false;
    },//async selectClient(id, index) {
    openDeletionConfirmation(customerId){
      this.errorTitle = "Confirmation de suppression"
      this.errorMessage = "Etes-vous sûr de vouloir supprimer ce client ? \n (cette action est irréversible)"
      this.customerId = customerId
      this.$bvModal.show('suppression-modal')
    },//openDeletionConfirmation(customerId){
    getStatusLabel(statusValue) {
      const status = this.statusOptions.find(option => option.value === statusValue);
      return status ? status.label : '';
    },//getStatusLabel(statusValue) {
    getFormatedDate(strDate) {
      const date = new Date(strDate)
      return date.toLocaleDateString()
    },//getFormatedDate(strDate) {
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },//sort(s) {
	isCustomer(){
		return authenticationService.isCustomer()
	},//isCustomer(){
	isAdmin(){
		return authenticationService.isAdmin()
	},//isAdmin(){
  },//methods: {
  async mounted () {
    if (this.isAdmin()) {
      await this.$store.dispatch('customer/getAll')
      this.customers = await this.$store.getters['customer/getAll']
    }
    else {
      const id = authenticationService.getUserId();
      await this.$store.dispatch('customer/getRelatedToCurrentUser', {id});
      this.customers = await this.$store.getters['customer/getRelatedToCurrentUser'];
    }

    if (this.customers.error) {
      this.errorTitle = "Get all error"
      this.errorMessage = util.getErrorMessage(this.customers.error)
      this.$bvModal.show('error-modal')
    } else {
      this.originCats = this.customers.map(customer => {
        return {
          client: customer.companyName,
          date: this.getFormatedDate(customer.updatedAt ? customer.updatedAt : customer.createdAt),
          description: customer.description,
          logo: customer.logo,
          id: customer.id,
          status: customer.projectStatus,
          manager: customer.projectManager,
          isLoading: false
        }
      })
      this.cats = this.originCats
    }
  },//async mounted () {
  computed: {
      filteredCats() {
          if (!this.searchInput) return this.cats;

          const search = this.searchInput.toLowerCase();
          return this.cats.filter(customer =>
              customer.client.toLowerCase().includes(search) ||
              customer.description.toLowerCase().includes(search));
      },
      sortedCats() {
          return this.filteredCats.slice().sort((a, b) => {
              let modifier = 1;
              if (this.currentSortDir === "desc") modifier = -1;
              if (this.currentSort === "date") {
                  const getDateValue = (dateString) => {
                      const [day, month, year] = dateString.split("/");
                      return new Date(year, month - 1, day);
                  };

                  const dateA = getDateValue(a[this.currentSort]);
                  const dateB = getDateValue(b[this.currentSort]);

                  return (dateA - dateB) * modifier;
              } else {
                  return (a[this.currentSort] < b[this.currentSort] ? -1 : 1) * modifier;
              }
          });
      },
      
  }
};
</script>

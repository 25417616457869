<template>
  <div id="header_retix" class="header_retix container-retix">
		<div class="header-item">		
          <router-link to="/" class="logo_retix mr-4">
            <img src="../assets/images/logo_retix.svg" alt="Logo Retix" border="0" />
          </router-link>
          <span class="version_class" v-if="showVersion()">{{ getVersion() }}</span>
		 </div> <!-- class="header-item" -->

		 <div class="spacer top-menu-middle" id="top-menu-middle">
			
		 </div> <!-- class="header-item" -->
		 
		 <div  v-if="!isLoginPage" class="header-item"><!-- Login -->
			<router-link v-if="!isAuthenticated()" to="/login" class="cta_secondary nav_retix">
            <span class="icon">
              <img src="../assets/images/icon_login.svg" alt="Icone connexion" border="0" />
            </span>
            <span class="txt">Connexion</span>
          </router-link>
		  
		 </div> <!-- class="header-item" Login -->
		 
		 <div v-if="!isLoginPage" class="header-item"><!-- HOME -->
 			<router-link  to="/" class="cta_secondary nav_retix">
 	            <span class="icon">
 	              <img src="../assets/images/icon_home.svg" alt="Icône d'accueil" border="0" />
 	            </span>
 	            <span class="txt">Accueil</span>
 	          </router-link>
 		 </div> <!-- class="header-item" HOME -->
		 
		 <div class="header-item"  v-if="isAdmin() || isUser()"><!-- USERS -->
 			<router-link :to="{name:'users',query:{_r:Date.now()}}" class="cta_secondary nav_retix" >
				<span class="icon">
	               <img src="../assets/images/icon_user-management.svg" alt="Utilisateurs" border="0" />
	             </span>
 	            <span class="txt">Utilisateurs</span>
 	          </router-link>
 		 </div> <!-- class="header-item" USERS -->
		 
		 <div v-if="!isCurrentPageSimulation() && getSimulation && getSimulation.id && !isLoginPage" class="header-item"><!-- SIMULATION -->
 			<router-link  :to="{ name: 'simulation' }" class="cta_secondary nav_retix">
                 <span class="icon">
                   <img src="../assets/images/simulation.svg" alt="Simulation" border="0" />
                 </span>
                 <span class="txt">Simulation</span>
               </router-link>
 		 </div> <!-- class="header-item"SIMULATION  -->

 		 <div class="header-item" v-if="isAdmin() || isUser()"><!-- NEW STUDY -->
 			<router-link  :to="{name: 'information', params: {source: 'menu', type: 'creation'}, query:{_r:Date.now()} }" class="cta_secondary nav_retix">
 	            <span class="icon">
 	              <img src="../assets/images/icon-study.svg" alt="Nouvelle étude" border="0" />
 	            </span>
 	            <span class="txt">Nouvelle étude</span>
 	          </router-link>
 		 </div> <!-- class="header-item"  NEW STUDY -->
		 
		<div class="header-item" v-if="isAuthenticated()">
			<b-dropdown class="currrent-user-dropdown" right no-caret>
				<template #button-content>
					<i class="fa fa-user" aria-hidden="true"></i>
				</template>
				<b-dropdown-header>Connecté en tant que :</b-dropdown-header>
				<b-dropdown-text>{{getLogin()}}</b-dropdown-text>
				<b-dropdown-text v-if="hasDisplayName()">{{getDisplayName()}}</b-dropdown-text>
				<b-dropdown-divider></b-dropdown-divider>
				
				<router-link to="/my-profile">
				<b-dropdown-item-button><i class="fa fa-pencil-square-o mr-2" aria-hidden="true"></i> Modifer mon profil</b-dropdown-item-button>
				</router-link>
				
				<b-dropdown-divider></b-dropdown-divider>
				<b-dropdown-item-button @click="showLogoutModal">
					<i class="fa fa-sign-out mr-2" aria-hidden="true"></i> Déconnexion
				</b-dropdown-item-button>
			</b-dropdown> 
		</div>

    <modal-logout-confirmation ref="logoutModal" title="Confirmation de déconnexion" message="Êtes-vous sûr de vouloir vous déconnecter ?"></modal-logout-confirmation>
  </div><!-- id="header_retix" class="header_retix container-retix" -->
</template>
<style scoped>
	
	.header_retix {
		display: flex;
		
		flex-direction:row;
		gap:25px;
		align-items: center;
		width: 100%;
		height: 80px;
		background-color: var(--bleu);
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.header-item {
		color: var(--blanc);
	}
	.spacer { flex-grow:1;}

	@media print {
		.header_retix {
			display: none;
		}
	}

	.logo_retix img {
		width: 110px;
	}

	.nav_retix {
		margin: 0;
		padding: 0;
		font-family: 'Raleway', sans-serif;
	}

	.nav_retix li {
		list-style: none;
		width: auto;
		display: inline;
		padding-right: 40px;
	}

	.nav_retix li:last-child {
		padding-right: 0px;
	}

	.nav_retix .icon img {
		width: 22px;
	}

	.nav_retix .txt {
		margin-left: 8px;
		color: var(--blanc);
		vertical-align: text-top;

	}
	
	/*CTA Secondary*/

	.cta_secondary {
		position: relative;
		text-decoration: none;
		cursor: pointer;
		text-align: left;
		color: var(--blanc);
	}

	.cta_secondary:after {
		bottom: -13px;
		right: 0px;
		left: 0px;
		height: 1px;
		z-index: 0;
		transform: scaleX(0);
		visibility: hidden;
		-webkit-transform-origin: right;
		transform-origin: right;
		transition: .7s transform cubic-bezier(.19, 1, .22, 1), visibility 0s .7s;
		-webkit-transition: .7s transform cubic-bezier(.19, 1, .22, 1), visibility 0s .7s;
		position: absolute;
		display: block;
		content: "";
		background-color: var(--blanc);
	}


	.cta_secondary:hover:after,
	.cta_secondary.actif:after {
		transform: scaleX(1);
		visibility: visible;
		-webkit-transform-origin: left;
		transform-origin: left;
		transition: .5s transform cubic-bezier(.19, 1, .22, 1);
		-webkit-transition: .5s transform cubic-bezier(.19, 1, .22, 1);
		background-color: var(--blanc);
	}
	
	::v-deep .currrent-user-dropdown {
		
		--currrent-user-dropdown-btn-size:50px;
		
		font-family: 'Raleway', sans-serif;
	  	color: var(--gris-fonce);
		
		button.btn {
			background:var(--bleu);
			border:2px solid white;
			height:var(--currrent-user-dropdown-btn-size);
			width:var(--currrent-user-dropdown-btn-size);
			border-radius:calc( var(--currrent-user-dropdown-btn-size) / 4 );
			padding:0;
			display:flex;
			align-items:center;
			justify-content:center;
			.fa { font-size:30px; }
		}/* button.btn */
		
		ul.dropdown-menu {
			width:350px;
			border-radius:calc( var(--currrent-user-dropdown-btn-size) / 4 );
			margin-top:calc( var(--currrent-user-dropdown-btn-size) / 8 );
		}/* ul */
	}/* ::v-deep .currrent-user-dropdown */
	
.top-menu-middle {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	color:white;
	a { color:white;  }
}
</style>
<script>
import ModalLogoutConfirmation from './ModalLogoutConfirmation.vue';
import { mapGetters } from "vuex";
import { version } from "../../package.json";
import {authenticationService} from '../services/authentication';

export default {
  name: "Menu",
  components: {
    ModalLogoutConfirmation
  },
  computed: {
    ...mapGetters({
      getSimulation: "customer/getSimulation"
    }),
    
    
    isLoginPage() {
      return this.$route.name === "login";
    }
  },
  beforeMount(){
	//console.log("src/components/Menu.vue:beforeMount()")
	authenticationService.menuComponent = this
  },//beforeMount(){
  methods: {
	 isAuthenticated() {
			return authenticationService.isLogged()
	  },
	  isAdmin() {
		return authenticationService.isAdmin()
       
      },
	  isUser() {
  		return authenticationService.isUser()
         
        },
    isCurrentPageSimulation() {
      return this.$route.name === "simulation";
    },
    getVersion() {
      return version;
    },
    showVersion() {
      return process.env.NODE_ENV;
    },
	
	hasDisplayName(){
		if(!authenticationService.isLogged())
			return false;
			
		let firstName = authenticationService.getFirstname()
		if(firstName)
			return true ;
			
		let lastName = authenticationService.getLastname()
		if(lastName)
			return true ;
		
		return false;
		
	},//hasDisplayName(){
	getLogin(){
		return authenticationService.getLogin()
	},//getLogin(){
	getDisplayName(){
  		if(!authenticationService.isLogged())
			return "";
			
		let firstName = authenticationService.getFirstname()
		let lastName = authenticationService.getLastname()
		let parts = [] ;
		
		if(firstName)
			parts.push(firstName)
		
		if(lastName)
			parts.push(lastName)
				
		//console.log("src/components/Menu.vue:getDisplayName()",parts)
		return parts.join(" ")
	},//getDisplayName(){
		
    showLogoutModal() {
      this.$refs.logoutModal.$bvModal.show('logout-modal');
    },
      goToWithoutCheck: function (route) {
          if (route === "/load_curve" && forms.customerForm.consumptionCurveForm) {
              this.$router.push(route);
          }
          if (route === "/scenario" && forms.customerForm.scenariosForm) {
              this.$router.push(route);
          }
          if (route === "/goal" && forms.customerForm.consumptionCurveForm.annualConsumption) {
              this.$router.push(route);
          }
          if (route === "/information") {
              this.$router.push(route);
          }
      }
  }
};
</script>

<template>
  <li class="col">
    <div class="bloc_content">
      <h2>Identité électrique</h2>
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group id="electrical-radio-group-subscription" class="abonnement">
          <label>Abonnement</label>
          <div class="row">
            <div class="col">
              <div
                :class="forms.customerForm.electricalIdentityForm.subscription=='blue'?'cta_abonnement actif':'cta_abonnement'"
              >
                <span class="rond type01"></span>
                <span class="txt">Bleu</span>
              </div>
            </div>
            <div class="col">
              <div
                :class="forms.customerForm.electricalIdentityForm.subscription=='yellow'?'cta_abonnement actif':'cta_abonnement'"
              >
                <span class="rond type02"></span>
                <span class="txt">Jaune</span>
              </div>
            </div>
            <div class="col">
              <div
                :class="forms.customerForm.electricalIdentityForm.subscription=='green'?'cta_abonnement actif':'cta_abonnement'"
              >
                <span class="rond type03"></span>
                <span class="txt">Vert</span>
              </div>
            </div>
          </div>
        </b-form-group>
        <!-- -->
        <hr />
        <!-- -->
        <b-form-group id="electrical-input-group-power" class="bloc_form">
          <label>
            Puissance souscrite
            <i>(kVA)</i>
          </label>
          <b-form-input
            id="electrical-input-power"
            v-model="forms.customerForm.electricalIdentityForm.subscribedPower"
            required
            class="input_form"
            type="number"
            step="any"
          />
        </b-form-group>
        <!-- -->
        <hr />
        <!-- -->
        <b-form-group id="electrical-input-group-peak-hours" class="bloc_form">
          <label>Heures creuses</label>
          <table class="table">
            <tbody>
              <tr v-for="(peak_hour, index) in forms.customerForm.electricalIdentityForm.offPeakHours" :key="index">
                <td>
                  <b-form-group id="electrical-input-group-start-peak-hours" label="Début" class="label02">
                    <b-form-input
                      id="electrical-input-start-peak-hours"
                      v-model="peak_hour.startOffPeakHours"
                      required
                      class="input_form"
                      type="time"
					  step="60"
                    />
                  </b-form-group>
                </td>
                <td>
                  <b-form-group id="electrical-input-group-end-peak-hours" label="Fin" class="label02">
                    <b-form-input
                      id="electrical-input-end-peak-hours"
                      v-model="peak_hour.endOffPeakHours"
                      required
                      class="input_form"
                      type="time"
					  step="60"
                    />
                  </b-form-group>
                </td>
                <td style="vertical-align:middle">
                  <button

                    class="removePeakHours"
                    type="button"
                    @click="removePeakHours(index)"
                    v-if="forms.customerForm.electricalIdentityForm.offPeakHours.length > 1"
                  >X</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="col align_right">
            <button
              id="button_add"
              type="button"
              class="cta_tertiaire"
              @click="addPeakHours"
            >Ajouter une tranche</button>
          </div>
        </b-form-group>
        <!--  -->
        <hr />
        <!--  -->
        <b-form-group id="electrical-checkbox-group-single-price" class="bloc_form">
          <div class="page__toggle">
            <label class="toggle">
              <input
                class="toggle__input"
                v-model="forms.customerForm.electricalIdentityForm.uniqueTariff"
                type="checkbox"
              />
              <span class="toggle__label">
                <span class="toggle__text">Tarif unique</span>
              </span>
            </label>
          </div>
        </b-form-group>
        <!-- -->
        <hr v-if="forms.customerForm.electricalIdentityForm.uniqueTariff" />
        <!-- -->
        <b-form-group
          id="electrical-input-group-purchase-price"
          class="bloc_form"
          v-if="forms.customerForm.electricalIdentityForm.uniqueTariff"
        >
          <label>
            Tarif achat
            <i>(€)</i>
          </label>
          <b-form-input
            id="electrical-input-purchase-price"
            v-model="forms.customerForm.electricalIdentityForm.purchaseTariff"
            required
            class="input_form"
            type="number"
            step="any"
          />
        </b-form-group>
        <!-- -->
        <hr v-if="!forms.customerForm.electricalIdentityForm.uniqueTariff" />
        <!-- -->
        <b-form-group
          id="electrical-input-group-HP-HC"
          v-if="!forms.customerForm.electricalIdentityForm.uniqueTariff"
          class="bloc_form"
        >
          <label>Tarifs</label>
          <b-form-row>
            <b-col>
              <b-form-group id="electrical-input-group-HPH" label="HPH (€/kWh)" class="label02">
                <b-form-input
                  id="electrical-input-HPH"
                  v-model="forms.customerForm.electricalIdentityForm.winterFullHoursTariff"
                  required
                  class="input_form"
                  type="number"
                  step="any"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="electrical-input-group-HCH" label="HCH (€/kWh)" class="label02">
                <b-form-input
                  id="electrical-input-HCH"
                  v-model="forms.customerForm.electricalIdentityForm.winterOffHoursTariff"
                  required
                  class="input_form"
                  type="number"
                  step="any"
                />
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group id="electrical-input-group-HPE" label="HPE (€/kWh)" class="label02">
                <b-form-input
                  id="electrical-input-HPE"
                  v-model="forms.customerForm.electricalIdentityForm.summerFullHoursTariff"
                  required
                  class="input_form"
                  type="number"
                  step="any"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="electrical-input-group-HCE" label="HCE (€/kWh)" class="label02">
                <b-form-input
                  id="electrical-input-HCE"
                  v-model="forms.customerForm.electricalIdentityForm.summerOffHoursTariff"
                  required
                  class="input_form"
                  type="number"
                  step="any"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form-group>
        <!-- -->
        <hr v-if="!forms.customerForm.electricalIdentityForm.uniqueTariff" />
        <b-form-group
          id="electrical-input-group-HE-HH"
          v-if="!forms.customerForm.electricalIdentityForm.uniqueTariff"
          class="bloc_form"
        >
          <label>Période correspondant HE/HH</label>
          <b-form-row>
            <b-col class="col-6">
              <b-form-group id="electrical-input-group-start-HE" label="Début HE" class="label02">
                <b-form-input
                  id="electrical-input-start-HE"
                  v-model="forms.customerForm.electricalIdentityForm.seasonReferentialFormatted.summerStartDate"
                  required
                  class="input_form"
                  placeholder="jj/mm"
                  :state="validateFormattedDate(forms.customerForm.electricalIdentityForm.seasonReferentialFormatted.summerStartDate)"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col class="col-6">
              <b-form-group id="electrical-input-group-start-HH" label="Début HH" class="label02">
                <b-form-input
                  id="electrical-input-start-HH"
                  v-model="forms.customerForm.electricalIdentityForm.seasonReferentialFormatted.winterStartDate"
                  required
                  class="input_form"
                  placeholder="jj/mm"
                  :state="validateFormattedDate(forms.customerForm.electricalIdentityForm.seasonReferentialFormatted.winterStartDate)"
                  type="text"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form-group>
        <!-- -->
        <hr />
        <!-- -->
        <b-form-group
          id="electrical-input-group-taxe"
          label-for="electrical-input-taxe"
          class="bloc_form"
        >
          <label>
            Turpe et taxe énergie
            <i>(€/kWh)</i>
          </label>
          <b-form-input
            id="electrical-input-taxe"
            v-model="forms.customerForm.electricalIdentityForm.energyTaxes"
            required
            class="input_form"
            type="number"
            step="any"
          />
        </b-form-group>
      </b-form>
    </div>
  </li>
</template>

<script>
import validation from "../utils/validation";

export default {
  data() {
    return {
      summerStartDatePrinted: '',
      winterStartDatePrinted: '',
      show: true
    };
  },
  watch: {
    "forms.customerForm.electricalIdentityForm.subscribedPower": {
      handler(newVal, oldVal) {
        const value = parseInt(newVal);

        if (isNaN(value)) {
          forms.customerForm.electricalIdentityForm.subscription = "";
        } else if (value <= 36) {
          forms.customerForm.electricalIdentityForm.subscription = "blue";
        } else if (value > 36 && newVal <= 250) {
          forms.customerForm.electricalIdentityForm.subscription = "yellow";
        } else if (value > 250) {
          forms.customerForm.electricalIdentityForm.subscription = "green";
        }
      }
    }
  },
  methods: {
    validateFormattedDate(value) {
      return validation.isDayMonthDateValid(value) ? null : false;
    },
    addPeakHours: function() {
      this.forms.customerForm.electricalIdentityForm.offPeakHours.push({startOffPeakHours: "", endOffPeakHours: ""});
    },
    removePeakHours: function(index) {
      this.forms.customerForm.electricalIdentityForm.offPeakHours.splice(
        index,
        1
      );
    },
    onSubmit(evt) {
      evt.preventDefault();
    },

    onReset(evt) {
      evt.preventDefault();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>

<template>
  <b-modal id="logout-modal" :title="title">
    <p>{{ message }}</p>

    <template v-slot:modal-footer="{ok}">
      <b-button size="sm" @click="ok()">NON</b-button>
      <b-button size="sm" @click="logout()">OUI</b-button>
    </template>
  </b-modal>
</template>

<script>
import {authenticationService} from '../services/authentication';

export default {
  props: ['message', 'title'],
  methods: {
    logout() {
		authenticationService.logout()
      
    }
  }
}
</script>

<template>
	
	<div class="content_retix user-form-view">
	<div class="container-retix">
        <div class="bloc_content">
            <h2>Informations de l'utilisateur</h2>
            <b-form @submit.prevent="onSubmit" v-if="show" class="user-form">
                <div class="form-columns">
					
                    <!-- Colonne Gauche -->
                    <div class="form-column">
                        <b-form-group id="user-input-group-email" class="bloc_form" :disabled="!forms.UserForm.canChangeLogin">
                            <label>Email / login :</label>
                            <b-form-input v-model="forms.UserForm.user.email" name="login" autocomplete="username" required class="input_form" @input="checkLoginAvailable"></b-form-input>
							<div v-if="loginCheckStatus == 'ok'" class="login-msg login-ok text-success" ><i class="fa fa-check"></i> Login OK</div>
							<div v-if="loginCheckStatus == 'warn'" class="login-msg login-error text-danger" ><i class="fa fa-times"></i> Login déjà utilisé</div>
                        </b-form-group>
						
						<b-form-group id="user-input-group-role" class="bloc_form" v-show="forms.UserForm.canChangeRole">
                        	<label>Rôle :</label>
                            <b-form-select
                                v-model="forms.UserForm.user.role"
                                :options="forms.UserForm.roleList"
								value-field="name"
								text-field="title"
                                required
								@change="checkPasswordRequirements"
                                class="input_form">
                            </b-form-select>
							
                        </b-form-group>

                        <b-form-group id="user-input-group-lastname" class="bloc_form">
                            <label>Nom :</label>
                            <b-form-input v-model="forms.UserForm.user.lastName" name="last-name" autocomplete="given-name" required class="input_form"></b-form-input>
                        </b-form-group>

                        <b-form-group id="user-input-group-firstname" class="bloc_form">
                            <label>Prénom :</label>
                            <b-form-input v-model="forms.UserForm.user.firstName" name="first-name" autocomplete="given-name" required class="input_form"></b-form-input>
                        </b-form-group>

						<b-form-group id="user-input-group-expiration-date" class="bloc_form" v-show="forms.UserForm.canChangeExpiration">
                            <label>Date d'expiration du compte : <br/><i>(vide = aucune expiration)</i></label>
							
                                <b-input-group>
                                    <b-form-input type="datetime-local" v-model="expirationDate" class="input_form"></b-form-input>
                                </b-input-group>
                            
                            
                                <b-button variant="warning" size="sm" @click="expireImmediately()" class="expire-btn expire-immediately">
                                    Expiration immédiate
                                </b-button>
								
								<b-button variant="secondary" size="sm" @click="resetExpiration()" class="expire-btn expire-reset">
                                    Effacer
                                </b-button>
                                    
                        </b-form-group>

                        
                    </div><!-- class="form-column" Colonne Gauche -->

                    <!-- Séparateur Vertical -->
                    <div class="separator"></div>

                    <!-- Colonne Droite -->
                    <div class="form-column">
						<template v-if="passwordKnown">
							<h4>Mot de passe connu</h4>
						
							<ClearPassword :clearPassword="forms.UserForm.user.password"/>
						</template>
						<h4>Changement de mot de passe</h4>
						
						<b-form-checkbox 
							v-model="passwordVisible" 
							v-show="!isCustomer()"
							@change="togglePasswordVisibility" 
							class="password-toggle">
						    Afficher les mots de passe
						    </b-form-checkbox>
						
						<b-button @click="generatePassword" variant="info" v-show="!isCustomer()">Générer un mot de passe</b-button>
						
						<b-form-group id="user-input-group-password" class="bloc_form" >
							
                            <label>Mot de passe :</label>
							
							<ul class="password-requirements">
	                            <li :class="'password-rule-'+passwordRulesStatus.minLength"><i class="fa mr-2" /> {{currentRolePasswordRules.minLength}} caractères minimum </li>
								<li :class="'password-rule-'+passwordRulesStatus.minLowerCase"><i class="fa mr-2" /> {{currentRolePasswordRules.minLowerCase}} lettres minuscules</li>
								<li :class="'password-rule-'+passwordRulesStatus.minUpperCase"><i class="fa mr-2" /> {{currentRolePasswordRules.minUpperCase}} lettres majuscules</li>
								<li :class="'password-rule-'+passwordRulesStatus.minDigits"><i class="fa mr-2" /> {{currentRolePasswordRules.minDigits}} chiffres</li>
								<li :class="'password-rule-'+passwordRulesStatus.minSpecials"><i class="fa mr-2" /> {{currentRolePasswordRules.minSpecials}} caractères spéciaux</li>
	                        </ul>
							
                            <b-form-input v-model="password" name="password" autocomplete="new-password" :type="passwordFieldType" class="input_form" @input="checkPasswordRequirements"></b-form-input>
                            
                        </b-form-group>
						
						
						<b-form-group id="user-input-group-password" 
						class="bloc_form"
						:class="'password-confirm-'+passwordConfirmMatchStatus" 
						:visible="forms.UserForm.canChangePassword">
                            <label>Confirmer le mot de passe :</label>
                            <b-form-input v-model="passwordConfirm" :type="passwordFieldType" name="password-confirm"  autocomplete="new-password" class="input_form" @input="checkPasswordConfirmation"></b-form-input>
                           
							<p v-if="passwordConfirmMatchStatus == 'ok'" class="password-confirm password-equals"><i class="fa fa-check mr-2"></i>Les mots de passe sont identiques</p>
							<p v-if="passwordConfirmMatchStatus == 'warn'" class="password-confirm password-mismatch"><i class="fa fa-times mr-2"></i>Les mots de passe sont différents</p>
                        </b-form-group>

						<h4>Suppression du compte</h4>
						<b-button @click="confirmDeleteAccount" variant="danger" v-show="forms.UserForm.canDelete"><i class="fa fa-trash mr-3"></i>Supprimer le compte</b-button>
                    </div><!-- class="form-column" Colonne Droite -->
					
                </div><!-- class="form-columns" -->
				
				<b-form-group id="user-input-group-projects" class="bloc_form" :visible="forms.UserForm.canChangeProjects">
                    <label>Études assignées :</label>
                    <b-table striped hover :items="forms.UserForm.user.customerList" :fields="CustomerFields" class="study-table" v-show="hasCustomerList()">
                        <template #cell(actions)="row">
                            <b-button variant="danger" @click="removeCustomer(row.index)" class="btn-sm" v-show="forms.UserForm.canChangeProjects">
                                <i class="fa fa-trash-o mr-2" aria-hidden="true"></i> Retirer
                            </b-button>
                        </template>
                    </b-table>
					<div class="no-study-assigned text-secondary font-italic" v-show="!hasCustomerList()">Aucune</div>
                </b-form-group>
				
                <b-button-group class="form-buttons">
                    <b-button type="submit" variant="primary" class="rounded-button" :disabled="submissionOngoing" style="max-width: 150px" >Enregistrer</b-button>
                </b-button-group>
            </b-form>
        </div>
        
		<div class="pt-5"></div>
        
        <div class="bloc_content" v-if="forms.UserForm.canResetLocking && forms.UserForm.accountLockStatus != null ">
            <h2>Statut du compte</h2>
            <div v-if="forms.UserForm.accountLockStatus.locked" class="lock-explanation" >
				<span class="text-danger msg"><i class="fa fa-times-circle mr-2"></i> Le compte est bloqué.</span>
				
				<label> Début :</label> <span>{{ formatDate(forms.UserForm.accountLockStatus.start) }}</span>
				<label> Fin :</label>   <span>{{ formatDate(forms.UserForm.accountLockStatus.end) }}</span>
				<label> Nombre d'échecs :</label>  <span>{{ forms.UserForm.accountLockStatus.loginAttempts.length }}</span>
				<label> Dernier échec :</label>  <span>{{ formatDate(lastFailure().date) }}</span>
				<label> Palier :</label>  <span>{{ forms.UserForm.accountLockStatus.stepCount }} échecs =&gt;
					{{ forms.UserForm.accountLockStatus.ammount }} {{ formatChronoUnit(forms.UserForm.accountLockStatus.unit, forms.UserForm.accountLockStatus.ammount ) }}</span>
				<div class="account-release"> 
					<b-form-checkbox
					      id="unlock-account-checkbox"
					      v-model="unlockAccount"
					    >Débloquer le compte</b-form-checkbox></div>
            </div>
            <p v-else class="text-success">
                <i class="fa fa-check-circle text-success"></i> Le compte n'est pas bloqué.
            </p>
            <h2>Échecs de connexion</h2>
            <b-table striped hover :items="forms.UserForm.accountLockStatus.loginAttempts"
                     :fields="[
                    { key: 'date', label: 'Date' },
                    { key: 'ip', label: 'Adresse IP' },
                    { key: 'cause', label: 'Cause' }
                ]" class="lock-table">
            </b-table>
        </div>
	</div><!-- class="content_retix user-form-view" -->
	<Teleport to="#top-menu-middle" v-if="linkBackToSearch">
			<router-link :to="{path:'users',query:{restorePrevious:true}}"  ><i class="fa fa-arrow-left mr-3" aria-hidden="true"></i> Retour à la recherche utilisateur</router-link>
		</Teleport>
	</div><!-- class="container-retix" -->
</template>

<style scoped>
h4 {
    margin-bottom: 2%;
}
.bloc_content {
    margin-left: 15%;
    margin-right: 15%;
}
/*
.user-form {
    margin-left: 5%;
    margin-right: 5%;
}
*/
.form-buttons {
    display: flex;
    justify-content: center;
}
.rounded-button {
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 30px !important;
}
.login-msg {
	margin: 8px 0 0 10px;
}
.password-toggle {
    margin-top: 10px;
    font-size: 0.9rem;
    padding: 0;
    margin-left: 10%;
}
.form-columns {
	margin:20px 0;
    display: flex;
    gap: 20px;
    align-items: flex-start;
}
.form-column {
    flex: 1;
	display:flex;
	flex-direction:column;
	gap:20px;
	padding: 20px 0;
	.bloc_form { margin:0; }
	h4 { margin:0; }
}
.separator {
    width: 1px;
    background-color: #a0a0a0;
    align-self: stretch;
    
}
.study-table {
    border: 1px solid #009fe3;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.no-study-assigned{
	
		
}

.expire-btn {
	margin-right:15px;
	margin-top:15px;
}

::v-deep .password-label-row {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin-bottom:8px;
	label { 
		flex-shrink:1;
		margin-bottom:0; 
	}
	
	div { 
		flex-grow:1; 
		margin-top:0;
		display:flex;
		flex-direction:row;
		align-items:center;
	}
}

.password-requirements {
    list-style: none;
    padding: 0;
    margin-top: 5px;
    margin-left: 10px;
	
	li {
	    font-size: 14px;
	    display: flex;
	    align-items: center;
	}

	li.password-rule-neutral {
		color:var(--gris);
		i.fa { display : none; }
	}
	
	li.password-rule-ok {
		color:var(--success);
		i.fa::before { content: "\f00c"; } /* fa-check */
	}
	
	li.password-rule-error {
		color:var(--danger);
		i.fa::before { content: "\f00d"; } /* fa-times */
	}
	
	li.password-rule-none {display:none;}
}

.password-confirm {
	margin: 8px 0 0 10px;
}
 
.password-equals {
	color:var(--success);
	
}

.password-mismatch {
	color:var(--danger);
}

.study-table {
	.study-name {
		width:99%;
	}
	
	.study-actions {
		min-width:120px;
		text-align:right;
	}
}

.lock-explanation {
	display:grid;
	grid-template-columns:auto auto;
	justify-content: start;
	grid-template-rows:auto;
	gap:15px;
	margin-bottom:15px;
	& >span.msg {
		text-align:left;
		grid-column-start:1;
		grid-column-end:span 2;
	}
	
	& > label { grid-column-start:1; color:var(--danger); margin:0; }
	& > span { grid-column-start:2; color:var(--danger); }
	
	div.account-release { 
		grid-column-start:1;
		grid-column-end:span 2;
		padding:10px;
		background-color: var(--warning);
	}
	
	
}
</style>

<script>
import Vue from 'vue';
import forms from "@/constante/Forms";
import {now} from "lodash";
import {userService} from "@/services/userService";
import {authenticationService} from '../services/authentication';
import utils  from '../utils/util.ts'
import Teleport from 'vue2-teleport';
import ClearPassword from '../components/ClearPassword.vue' ;

export default {
	components: {
	    Teleport,
		ClearPassword
	  },
    data() {
        return {
            show: true,
            passwordFieldType: "password",
            passwordVisible: false,
			linkBackToSearch: false,
            passwordKnown:false,
			unlockAccount:false,
			expirationDate:null,
            CustomerFields: [
                { key: "companyName", label: "Nom", class:"study-name" },
                { key: "actions", label: "Actions", class:"study-actions" },
            ],
			password:"",
			passwordConfirm:"",
			
			/*
				loginCheckStatus (pour l'UI)
				3 status possibles 
				silent quand le champ login fait moins de 3 caractères pas de controles
				warn quand le champ login est déjà utilisé pour un autre utilisateur
				ok  quand le champ login est ok
			*/
			loginCheckStatus:"silent",
			
			/* 
				true / false (pour les controles logique métier)
			*/
			passwordConfirmMatches:false, 
			
			/*
				passwordConfirmMatches (pour l'UI)
				3 status possibles 
				silent quand le champ confirm est encore vide
				warn quand le champ confirm n'est pas identique au mot de passe
				ok  quand le champ confirm est identique au mot de passe
			*/
			passwordConfirmMatchStatus:"silent",
			passwordConfirmMatchTimer:null,//le timer qui attends 1 seconde après la saisie pour contrôler que le mot de passe et la confirmation sont identiques
			passwordRulesStatus:{
				correct:true,//utilisé pour les controles par pour l'ui
				/* 4 status possibles pour chaque règle 
					neutral : règle pas controllée (quand le mot de passe n'est pas changé, ou le contrôle n'est pas pertinent)
					none : règle pas appliqée 
					ok : règle respectée
					error : règle enfreinte
				*/ 
				minLength : 'neutral',
				minLowerCase : 'neutral',
				minUpperCase : 'neutral',
				minDigits : 'neutral',
				minSpecials : 'neutral',
			},
			currentRolePasswordRules : {},
			sourceUser:{},
			loginHasError:false,
			submissionOngoing:false
        };
    },
    async mounted() {
		//console.log("src/views/UserUpdate.vue:mounted()",this.$router);
		
		let userId = this.$route.query.userId ;
		let mode = 'UPDATE'
		if(this.$route.name ==='my-profile'){ 
			mode = "SELF"
			userId = null ;
		}
		if(this.$route.name ==='create-user'){ 
			mode = "CREATE"
			userId = null ;
		}	
			
		if(this.$route.query.linkBackToSearch)
			this.linkBackToSearch = true
		
        forms.UserForm = await userService.getUserFormInfo(userId,mode);
		this.sourceUser = {... forms.UserForm.user}
		if(this.sourceUser.expirationDateTime){
			this.expirationDate = this.sourceUser.expirationDateTime.substring(0,16)
		}
		
		if(!forms.UserForm.canChangeProjects ){
			// console.log("src/views/UserUpdate.vue:mounted()", this.CustomerFields)
			this.CustomerFields = this.CustomerFields.filter( (c) => { return c.key === 'companyName' })
			// console.log("src/views/UserUpdate.vue:mounted()", this.CustomerFields)
		}
		
		
		if(!forms.UserForm.user.customerList){
			forms.UserForm.user.customerList=[]
		}
		//console.log("src/views/UserUpdate.vue:mounted()", forms.UserForm.user.customerList)
		//console.log("src/views/UserUpdate.vue:mounted()", forms.UserForm.user.customerList)
		
		if(this.sourceUser.password)
			this.passwordKnown = this.sourceUser.password.length > 0
		//console.log("src/views/UserUpdate.vue:mounted()",forms.UserForm)
        //forms.UserForm.user.customers = this.transformCustomerMap(forms.UserForm.user.customers);
		
        if (!forms.UserForm.user.password) {
            forms.UserForm.user.password = "";
        }
        this.checkPasswordRequirements();
		
        //console.log("src/views/UserUpdate.vue:mounted()",forms.UserForm);
    },
    methods: {
        onSubmit() {
			let formInfos = forms.UserForm
			let formUser = formInfos.user
			
			if(!this.checkDataAndWarn())
				return
			
			let userToSubmit = {}
			
			userToSubmit.id = this.sourceUser.id
			userToSubmit.timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
			userToSubmit.firstName = formUser.firstName
			userToSubmit.lastName = formUser.lastName
			if(this.password && this.password.length)
				userToSubmit.password = this.password
			
			userToSubmit.email = this.sourceUser.email
			if(formInfos.canChangeLogin)
            	userToSubmit.email = formUser.email
			
			userToSubmit.role = this.sourceUser.role
			if(formInfos.canChangeRole)
            	userToSubmit.role = formUser.role

			
			if(formInfos.canChangeExpiration){
				let expirationDate = this.expirationDate
				if(expirationDate && expirationDate.length >0 ){
					//console.log("src/views/UserUpdate.vue:onSubmit()",expirationDate);
					userToSubmit.expirationDateTime = expirationDate;
				}else{
					userToSubmit.expirationDateTime = null ;
				}
				
            		
			}
			
			userToSubmit.lockRelease = this.sourceUser.lockRelease
			if(formInfos.canResetLocking && this.unlockAccount){
				let lockRelease = new Date();
				userToSubmit.lockRelease = lockRelease.toISOString();	
			}
			
			if(formInfos.canChangeProjects){
				if(this.hasCustomerList())
					userToSubmit.customerList =  forms.UserForm.user.customerList
				else
					userToSubmit.customerList = []
			}
			
			this.submissionOngoing = true
			console.log("src/views/UserUpdate.vue:onSubmit()",userToSubmit);
			let submissionPromise = userService.submitUser(userToSubmit)
			submissionPromise.then((response)=>{
				this.submissionOngoing = false
				
				if(response.id){
					Vue.toasted.success("Modifications enregistrées");
				}else{
					Vue.toasted.error("Erreur : "+response.exception);
					
				}
				//console.log("src/views/UserUpdate.vue:onSubmit() / submissionPromise.then",x);
				
			})//submissionPromise.then((response)=>{
			.catch((error)=>{
				this.submissionOngoing = false
				//console.log("src/views/UserUpdate.vue:onSubmit() / submissionPromise.catch",{...error});
				let errorMessage = error.response.data.exception.message
				if(errorMessage)
					Vue.toasted.error(errorMessage);
				else
					Vue.toasted.error(error);
			})// .catch((error)=>{
        },
		
		checkDataAndWarn(){
			let result = true ;
			let formInfos = forms.UserForm
			let formUser = formInfos.user
			let login = formUser.email
			if(!login || login.trim().length <=0){
				this.loginHasErrors = true
				Vue.toasted.error("Le login / email est obligatoire");
				result = false
			}else{
				this.loginHasErrors = false
			}
			
			//console.log("src/views/UserUpdate.vue:onSubmit()", this.password, this.password.length, this.passwordRulesStatus)
			if(this.password && this.password.length && !this.passwordRulesStatus.correct ){
				Vue.toasted.error("Le mot de passe est incorrect");
				result = false
			}
			
			return result;
		},
        
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordVisible ? "text" : "password";
        },
        removeCustomer(index) {
            this.forms.UserForm.user.customerList.splice(index, 1);
        },
        expireImmediately() {
			let now = new Date();
			now = now.toISOString();
			now = now.substring(0,16)
            this.expirationDate = now;
        },
		resetExpiration(){
			this.expirationDate = ""
		},
		
		getPasswordRequirements(){
			let role = this.forms.UserForm.user.role ;
			for(let currentRule of this.forms.UserForm.passwordConstraintList){
				if(currentRule.role === role)
					return currentRule ;
				
			}
			console.warn("src/views/UserUpdate.vue:getPasswordRequirements()",`No password constraints defined for role ${role}`,this.forms.UserForm.passwordConstraintList);
			return null 
		},
		
		generatePassword(){
			let rule = this.getPasswordRequirements()
			if(rule == null)
				return;
			
			let generated = utils.generatePassword(rule);
			
			this.password = generated
			this.passwordConfirm = generated
			
			this.checkPasswordRequirements()
			this.checkPasswordConfirmation()
			
			this.passwordVisible = true
			this.togglePasswordVisibility()
			
			
		},
        checkPasswordRequirements() {
            const password = this.password;
			this.passwordRulesStatus.correct = true ; 
			this.passwordRulesStatus.minLength = 'neutral'
			this.passwordRulesStatus.minLowerCase = 'neutral'
			this.passwordRulesStatus.minUpperCase = 'neutral'
			this.passwordRulesStatus.minDigits = 'neutral'
			this.passwordRulesStatus.minSpecials = 'neutral'
			
			
			let rule = this.getPasswordRequirements()
			if(rule == null){
				
				return;
			}else{
				this.currentRolePasswordRules = rule
				
			}
			
			
			//console.log("src/views/UserUpdate.vue:checkPasswordRequirements()",rule);
			this.passwordRulesStatus.minLength = computePasswordConstraintStatus(rule.minLength,password.length,password.length>0);
						
            let minSignificantLength = rule.minLowerCase + rule.minUpperCase + rule.minDigits + rule.minSpecials ;
			let significant =  password.length > minSignificantLength
			
			let nbLower = password.replace(/[^a-z]/g, '').length;
			let nbUpper = password.replace(/[^A-Z]/g, '').length;
			let nbDigits = password.replace(/[^0-9]/g, '').length;
			let nbSpecials = password.length - nbLower - nbUpper - nbDigits
			
			this.passwordRulesStatus.minLowerCase = computePasswordConstraintStatus(rule.minLowerCase,nbLower,significant);
			this.passwordRulesStatus.minUpperCase = computePasswordConstraintStatus(rule.minUpperCase,nbUpper,significant);
			this.passwordRulesStatus.minDigits = computePasswordConstraintStatus(rule.minDigits,nbDigits,significant);
			this.passwordRulesStatus.minSpecials = computePasswordConstraintStatus(rule.minSpecials,nbSpecials,significant);
			
			let correct = true ;
			correct = correct && checkPasswordCriteriaPasses(this.passwordRulesStatus.minLength)
			correct = correct && checkPasswordCriteriaPasses(this.passwordRulesStatus.minLowerCase)
			correct = correct && checkPasswordCriteriaPasses(this.passwordRulesStatus.minUpperCase)
			correct = correct && checkPasswordCriteriaPasses(this.passwordRulesStatus.minDigits)
			correct = correct && checkPasswordCriteriaPasses(this.passwordRulesStatus.minSpecials)
			this.passwordRulesStatus.correct = correct ; 
			
			if(this.passwordConfirm.length > 0)
				this.checkPasswordRequirementsDelayed()
			//console.log("src/views/UserUpdate.vue:checkPasswordRequirements()",password,{...this.passwordRulesStatus});
        },
		
		checkPasswordConfirmation(){
			//console.log("src/views/UserUpdate.vue:checkPasswordConfirmation()",this.password,this.passwordConfirm,this.passwordConfirmMatchTimer);
			
			if(this.passwordConfirmMatchTimer)
				clearTimeout(this.passwordConfirmMatchTimer)

			this.passwordConfirmMatchStatus = 'silent' ;
			
				
			if(this.password === this.passwordConfirm){
				this.passwordConfirmMatches = true ;
				this.passwordConfirmMatchStatus = 'ok' ;
				return;
			}
			
			if(this.password.length <= 0 && this.passwordConfirm.length <= 0 )
				return
			
			this.passwordConfirmMatchTimer = setTimeout(this.checkPasswordRequirementsDelayed.bind(this),1000)
		},
		
		checkPasswordRequirementsDelayed(){
			this.passwordConfirmMatches = this.password === this.passwordConfirm
			if(this.passwordConfirmMatches)
				this.passwordConfirmMatchStatus = 'ok'
			else
			this.passwordConfirmMatchStatus = 'warn'
		},
		
		lastFailure(){
			let list = forms.UserForm.accountLockStatus.loginAttempts
			if(!list)
				return null ;
			let len = list.length
			if( len <= 0 )
				return null ;
			
			return list[len-1]
		},
		checkLoginAvailable(){
			let userId = this.sourceUser.id
			let login = forms.UserForm.user.email
			if(login.length<=3)
				return
			
			//console.log("src/views/UserUpdate.vue:checkLoginAvailable()",login,userId)
			let checkLoginAvailablePromise = userService.checkLoginAvailable(login,userId)
			checkLoginAvailablePromise.then((response)=>{
				
				//console.log("src/views/UserUpdate.vue:checkLoginAvailable() // promise.then",response)
				// response est un boolean car l'api retourne un boolean'
				if(response)
					this.loginCheckStatus = 'ok'
				else
					this.loginCheckStatus = 'warn'
				
			})//checkLoginAvailablePromise.then((response)=>{
		},// checkLoginAvailable(){
		async confirmDeleteAccount(){
			if(!this.sourceUser)
				return 
			
			let msgBoxOptions = {
				okTitle:'Supprimer le compte',
				okVariant:'danger',
				cancelTitle:'Annuler',
				title:"Êtes vous sûr de vouloir supprimer ce compte ?",
				
			}
			console.log({...this.sourceUser})
			let message = [] 
			if(this.sourceUser.firstName)
				message.push(this.sourceUser.firstName)
			if(this.sourceUser.lastName)
				message.push(this.sourceUser.lastName)
			if(message.length<=0)
				message.push(this.sourceUser.email)
			else
				message.push("( "+this.sourceUser.email+" )")
			message = message.join(" ")
			let confirmed = await this.$bvModal.msgBoxConfirm(message, msgBoxOptions)
			
			if(!confirmed)
				return;
			userService.deleteUser(this.sourceUser.id)
			
			await this.$bvModal.msgBoxOk("Compte supprimé !")
			this.$router.push({path:'users',query:{restorePrevious:true,refreshPrevious:true}})
		},//confirmDeleteAccount(){
        formatDate(dateString) {
            if (!dateString) return "-";
            const date = new Date(dateString);
            return date.toLocaleString();
        },
		formatChronoUnit(javaChronoUnit, ammount){
			
			return utils.javaChronoUnitToText(javaChronoUnit, ammount)
		},
		hasCustomerList(){
			if(!forms.UserForm.user.customerList)
				return false;
			return forms.UserForm.user.customerList.length>0
		},
		/*
		javaChronoUnitToText(unit){
			return javaChronoUnitToText(unit)
		},
		*/
		isAdmin() {
            return authenticationService.isAdmin() 
        },
        isUser() {
			return authenticationService.isUser() 
        },
        isCustomer() {
			return authenticationService.isCustomer() 
        },
    },
    computed: {
        forms() {
            return forms;
        },
        
        roleOptions() {
            return this.forms.UserForm.roleList.map(role => ({
                value: role.id,
                text: role.title
            }));
        },
        
        isLocked() {
            return this.forms.UserForm.user.lockStart && (!this.forms.UserForm.user.lockEnd || new Date(this.forms.UserForm.user.lockEnd) > new Date());
        }
    },
};

function computePasswordConstraintStatus(min,count, significant){
	if(min <= 0 )
		return 'none'
	
	if(count >= min)
		return 'ok'
		
	if(significant)
		return 'error'
	else
		return 'neutral'
}

function checkPasswordCriteriaPasses(criteria){
	if(criteria === 'ok')
		return true
	if(criteria === 'none')
		return true
		
	return false
			
}



</script>
<template>
<b-modal ref="bModal"
		dialog-class="check-result-modal-dialog" 
		title="Contrôle détaillé du formulaire" 
		no-fade ok-only ok-title="Retour" 
		:ok-variant="(formChecker && formChecker.isValid())?'outline-success':'outline-secondary'">
		<template v-if="formChecker">
			<p v-if="formChecker.isValid()" class="text-success"> <i class="fa fa-check mr-3"></i>Formulaire valide</p>
			<table v-if="formChecker.rejectList.length>0">
				<tr>
					<th>Champ</th>
					<th>Valeur</th>
					<th>Problème</th>
					<th>Description</th>
					<th>Infos sup.</th>
				</tr>
				<tr v-for="(reject, i) in formChecker.rejectList" :key="i">
					
					<td>{{reject.fieldName}}</td>
					<td><template v-if="reject.rejectedContent !=null && reject.rejectedContent!=reject.fieldValue">{{reject.fieldValue}}</template></td>
					<td class="text-danger">{{reject.rejectedContent}}</td>
					<td>{{formChecker.formatReject(reject)}}</td>
					<td>
						<template v-if="reject.rejectedParams" >
							<div v-for="(paramValue, paramKey ) in reject.rejectedParams" :key="paramKey">
								{{paramKey}} : {{paramValue}}
							</div>
						</template>
					</td>
				</tr>
			</table>
		</template>
</b-modal><!-- check-result-modal -->

</template>
<style>
.check-result-modal-dialog.modal-dialog.modal-md {
	max-width:1000px;
	tr:hover {
		background:#CCC;
	}
	th,td {
		padding: 5px;
	}
}
</style>
<script>
import { FormChecker } from '@/utils/form-checker.ts'
export default {
	expose: ['showModal'],
	props:	{
		formCheckerProvider:{ 
			type:Function, 
			required:false 
		},
		
	},//props:	{
	data(){
		return {
			formChecker:null
		}
	},//data(){
	mounted() {
		this.documentKeydownListener = this.onDocumentKeydown.bind(this)
		document.addEventListener("keydown",this.documentKeydownListener)
	},//mounted() {
		
	destroyed() {
		document.removeEventListener("keydown",this.documentKeydownListener)
	},//destroyed() {
		
	methods : {
		onDocumentKeydown(event){
			if( event.key !== "F1")
				return;
			
			//console.log("src/view/Information.vue:onDocumentKeydown()",event)	
			this.showModal();
		},//
		
		showModal(){
			this.formChecker = this.formCheckerProvider()
			this.$refs.bModal.show()
			//console.log("src/components/FormCheckerModal.vue:showModal()")	
				
		},
	},//methods : {
}
</script>
<template>
	<div class="content_retix user-management-view">
	<div class="container-retix">
		<div class="user-management-header">
			<h1>Gestion des utilisateurs</h1>
			<router-link :to="{name:'create-user',query:{linkBackToSearch:true}}" class="btn btn-success form-actions" >
				<i class="fa fa-plus" aria-hidden="true"></i> Nouvel utilisateur
			</router-link>
		</div>
		<div class="pt-5"></div>
		<div class="bloc_content">
			<h2>Recherche</h2>
			<form @submit.prevent="userSearch">
			<div class="user-search-options">
				
				
				<div class="user-search-field text-user-search-field">
					<b-tooltip target="text-field-tooltip-trigger" 
						triggers="click"
						custom-class="user-search-field-tooltip">
						<p>Texte : Recherche dans les champs Login/email, prénom, nom.</p>
						<p>Les utilisateurs dont un des champ <b class="text-info">contient</b> la valeur recherchée sont retournés.</p> 
						<p>Recherche insensible à la casse</p> 
					</b-tooltip>
					<div  class="label-with-tooltip">
						<label for="text-user-search-input">Texte</label>
						<i id="text-field-tooltip-trigger" class="help-icon fa fa-question-circle" aria-hidden="true"></i>
					</div><!-- id="text-field-tooltip-trigger" -->
					<input type="text" id="text-user-search-input" 
					v-model="searchParams.text" 
					class="user-search-input" />
				</div><!-- user-search-field text-user-search-field -->
				
				<div class="user-search-field login-user-search-field">
					<b-tooltip target="login-field-tooltip-trigger" 
						triggers="click" 
						custom-class="user-search-field-tooltip" >
						<p>Recherche dans le champ login/email</p>
						<p>Par défaut : recherche exacte</p>
						<p>test<b class="text-info">%</b> : recherche les logins <b class="text-info">commençants</b> par test</p> 
						<p><b class="text-info">%</b>test : recherche les logins <b class="text-info">finissants</b> par test</p> 
						<p><b class="text-info">%</b>test<b class="text-info">%</b> : recherche les login <b class="text-info">contenants</b> test</p>
						<p>Recherche insensible à la casse</p>
					</b-tooltip>
					<div class="label-with-tooltip">
						<label for="login-user-search-input">Login/email</label>
						<i id="login-field-tooltip-trigger" class="help-icon fa fa-question-circle" aria-hidden="true"></i>
					</div><!-- id="login-field-tooltip-trigger" -->
					<input type="text" id="login-user-search-input" 
					v-model="searchParams.login" 
					class="user-search-input" />
				</div><!-- user-search-field login-user-search-field -->
				
				<div class="user-search-field firstname-user-search-field">
					<b-tooltip target="firstname-field-tooltip-trigger" 
						triggers="click" 
						custom-class="user-search-field-tooltip" >
						<p>Recherche dans le champ prénom</p>
						<p>Par défaut : recherche exacte</p>
						<p>test<b class="text-info">%</b> : recherche les prénoms <b class="text-info">commençants</b> par test</p> 
						<p><b class="text-info">%</b>test : recherche les prénoms <b class="text-info">finissants</b> par test</p> 
						<p><b class="text-info">%</b>test<b class="text-info">%</b> : recherche les prénoms <b class="text-info">contenants</b> test</p>
						<p>Recherche insensible à la casse</p>
					</b-tooltip>
					<div  class="label-with-tooltip">
						<label for="firstname-user-search-input">Prénom</label>
						<i id="firstname-field-tooltip-trigger" class="help-icon fa fa-question-circle" aria-hidden="true"></i>
					</div>
					<input type="text" id="firstname-user-search-input" 
					v-model="searchParams.firstname" 
					class="user-search-input" />
				</div><!-- user-search-field firstname-user-search-field -->
				
				<div class="user-search-field lastname-user-search-field">
					<b-tooltip target="lastname-field-tooltip-trigger" 
						triggers="click" 
						custom-class="user-search-field-tooltip" >
						<p>Recherche dans le champ nom</p>
						<p>Par défaut : recherche exacte</p>
						<p>test<b class="text-info">%</b> : recherche les noms <b class="text-info">commençants</b> par test</p> 
						<p><b class="text-info">%</b>test : recherche les noms <b class="text-info">finissants</b> par test</p> 
						<p><b class="text-info">%</b>test<b class="text-info">%</b> : recherche les noms <b class="text-info">contenants</b> test</p>
						<p>Recherche insensible à la casse</p>
					</b-tooltip>
					<div  class="label-with-tooltip">
						<label for="lastname-user-search-input">Nom</label>
						<i id="lastname-field-tooltip-trigger" class="help-icon fa fa-question-circle" aria-hidden="true"></i>
					</div>
					<input type="text" id="lastname-user-search-input" 
					v-model="searchParams.lastname" 
					class="user-search-input" />
				</div><!-- user-search-field lastname-user-search-field -->
				
				<div class="user-search-field role-user-search-field">
					<label for="role-user-search-input">Rôle</label>
					<select 
						id="role-user-search-input" 
						v-model="searchParams.requiredRole" 
						class="user-search-input" >
						<option :value="null" >- Indifférent -</option>
						<option v-for="role in searchInfos.roleList" 
						:key="role.id" 
						:value="role.name">{{ role.title }}</option>
					</select>
				</div><!-- user-search-field role-user-search-field -->
				
				<div class="user-search-field orphan-user-search-field">
					<label for="orphan-user-search-input">Orphelin <small>(rattaché à aucune étude)</small></label>
					<select 
						id="orphan-user-search-input" 
						v-model="searchParams.orphan" 
						class="user-search-input" >
						<option :value="null" >- Indifférent -</option>
						<option :value="true" >Oui</option>
						<option :value="false" >Non</option>
					</select>
				</div><!-- user-search-field orphan-user-search-field -->
				
				<div class="user-search-field locked-user-search-field">
					<label for="locked-user-search-input">Compte bloqué</label>
					<select 
						id="locked-user-search-input" 
						v-model="searchParams.locked" 
						class="user-search-input" >
						<option :value="null" >- Indifférent -</option>
						<option :value="true" >Oui</option>
						<option :value="false" >Non</option>
					</select>
				</div><!-- user-search-field lastname-user-search-field -->
				
				<div class="user-search-field expired-user-search-field">
					<label for="expired-user-search-input">Expiration du compte</label>
					<select 
						id="expired-user-search-input" 
						v-model="searchParams.expired" 
						class="user-search-input" >
						<option :value="null" >- Indifférent -</option>
						<option :value="true" >Expiré</option>
						<option :value="false" >Non expiré</option>
						<option :value="'future'" >Expiration à venir</option>
					</select>
				</div><!-- user-search-field expired-user-search-field -->
				<input type="submit" 
					class="btn btn-primary user-search-button" 
					value="Rechercher"/>
			</div><!-- class="user-search-options" -->
			</form>
		</div><!-- class="bloc_content" -->
		
		<div class="pt-5"></div>
		<div class="bloc_content">
			<h2>Utilisateurs</h2>
			<div class="user-no-search" v-if="!searchResultReceived">Aucune recherche effectuée</div>
			<div class="user-search-result"
			:class="{'blink':blinkSearchResult}" v-if="searchResultReceived">
				<div class="user-search-no-result" v-if="foundUsers.length == 0">Aucun utilisateur ne correspond</div>
				<UserList :content="this.foundUsers"
							v-if="foundUsers.length > 0"
							linkLabel="Modifier"
						/>
			</div><!-- class="user-search-result" -->
		</div><!-- class="bloc_content" -->
	</div><!-- class="container-retix" -->
	</div><!-- class="content_retix" -->
</template>
<style scoped>
.user-management-header {
	display:flex;
	flex-direction:row;
	h1 { 
		flex-grow:1; 
		margin:0}
	a { 
		display:flex;
		flex-direction:row;
		align-items:baseline;
		gap:5px;
	}
}
.user-search-options {
	display:grid;
	grid-template-columns:repeat(4,1fr);
	gap:10px;
}

.user-search-field {
	display:flex;
	flex-direction:column;
	gap:5px;
	
	label { margin:0; }
	
}

.user-search-input {
	border:1px solid var(--bleu);
	border-radius:10px;
	padding:5px;
	width:100%;
	background:white;
	height:30px;
}

.user-search-button {
  grid-column-start: 4; 
  place-self: end;
  padding:5px;
}

.btn {
	border-radius:10px;
}

.label-with-tooltip {
	display:flex;
	flex-direction:row;
	gap:10px;
	align-items:center;
	--justify-content:space-between;
}

.help-icon {
	color: #BBB;
	cursor:pointer;
}



</style>
<style>
.tooltip.user-search-field-tooltip {
	
	.tooltip-inner {
		min-width:400px;
		text-align:left;
		p { margin-bottom:5px; }
	}
}
</style>
<script>
import { userService } from "@/services/userService" ;
import UserList from "../components/UserList.vue";
import {authenticationService} from '../services/authentication';

export default {
	components:{
			UserList
		},
	data() {
	    return {
			searchParams : {
				text:"",
				login:"",
				firstname:"",
				lastname:"",
				requiredRole:null,
				locked:null,
				orphan:null,
				expired:null
			},
			foundUsers: [],
			searchResultReceived:false,
			blinkSearchResult:false,
			searchInfos:{}
		}// return
	},// data()
	
	beforeCreate(){
		//console.log("src/views/Users.vue:beforeCreate()")
		let restorePrevious = this.$route.query.restorePrevious
		
				 
		if(restorePrevious)
			return
				
		let userSearchInfosPromise = userService.getSearchInfos()
		userSearchInfosPromise.then((searchInfos)=>{
			this.searchInfos = searchInfos
			//console.log("src/views/Users.vue:beforeCreate() / userSearchInfosPromise.then", this.searchInfos)
		})//userSearchInfosPromise.then((searchInfos)=>{
	},//beforeCreate(){
	created(){
		//console.log("src/views/Users.vue:created()")
		
		let restorePrevious = this.$route.query.restorePrevious
		 
		if(restorePrevious){
		 	this.restore()
		}
				
	},// created(){
	methods: {
		backup(includeResults = true){
			
			
			let backupData = {
				backupDate:Date.now(),
				backupContainsResults:false,
				searchParams: this.searchParams,
				searchInfos:this.searchInfos
			}
			
			if(includeResults){
				backupData.backupContainsResults = true
				backupData.foundUsers = this.foundUsers
				backupData.searchResultReceived=this.searchResultReceived
			}
			
			let backupDataJson = JSON.stringify(backupData);
			localStorage.setItem("user-search-backup-data",backupDataJson)
		},//backup(){
			
		restore(){
			let backupDataJson = localStorage.getItem("user-search-backup-data")
			if(backupDataJson==null)
				return;
			
			let backupData = JSON.parse(backupDataJson);
			
			this.searchParams = backupData.searchParams
			this.searchInfos = backupData.searchInfos
			
			let refreshPrevious = this.$route.query.refreshPrevious
			if(refreshPrevious){
				this.doSearch()
				return
			}
			
			if(!backupData.backupContainsResults)
				return
			
			this.foundUsers = backupData.foundUsers
			this.searchResultReceived = backupData.searchResultReceived
			
			
		},//restore(){
		
		doSearch(){
			let searchParams = {... this.searchParams }
			let userSearchResultPromise = userService.search(searchParams)
			userSearchResultPromise.then((searchResult)=>{
				this.foundUsers = searchResult
				for(let user of this.foundUsers){
					this.linkUser(user)
				}
				this.searchResultReceived = true
				this.blinkSearchResult = false
				this.backup(true)
				//this.$forceUpdate()
				setTimeout(()=>{
					this.blinkSearchResult = true
					//this.$forceUpdate()
				},10)
			})// userSearchResultPromise.then((searchResult)=>{
		},//doSearch(){
		
		userSearch(event){
			//event.preventDefault();
			this.backup(false)
			
			this.doSearch()
		},//userSearch(event){
		
		linkUser(user){
			let shallLink = false
			if(user.role === 'customer')
				shallLink = true
				
			if(authenticationService.isAdmin())
				shallLink = true

			//console.log("src/views/Users.vue:linkUser()",user.role,authenticationService.isAdmin(),shallLink)
				
			if(!shallLink){
				user.link = false
				return 
			}
				 
				
				
			user.link = {path:'/edit-user',query:{userId:user.id,linkBackToSearch:true}}
			//console.log("src/views/Users.vue:linkUser()",user)
		},//linkUser(user){
	},//methods: {
}// export default {
	

</script>
<template>
  <li class="project-identity-block">
    <div class="bloc_content">
      <h2>Identité du projet</h2>
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group id="project-status-group" label="Statut du projet" class="bloc_form">
          <select
		  	id="project-management-status"
              v-model="forms.customerForm.projectIdentityForm.projectStatus"
              class="form-control input_form"
              required
          >
            <option v-for="status in statusOptions" :key="status.id" :value="status.value">
              {{ status.label }}
            </option>
          </select>
        </b-form-group>
        <b-form-group label="Responsable de projet" id="project-manager-group" class="bloc_form">
          <b-form-input
		  id="project-manager"
              v-model="forms.customerForm.projectIdentityForm.projectManager"
              class="input_form"
              required
          ></b-form-input>
        </b-form-group>
      </b-form>
    </div>
  </li>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      statusOptions: [
        {id: 1, value: 'GAGNE', label: 'Gagnée'},
        {id: 2, value: 'OUVERTE', label: 'Ouverte'},
        {id: 3, value: 'PERDUE', label: 'Perdue'},
        {id: 4, value: 'EN_RETARD', label: 'En retard'},
        {id: 5, value: 'ANNULE', label: 'Annulée'},
        {id: 6, value: 'FERME', label: 'Fermée'},
      ],
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
    },
    onReset(evt) {
      evt.preventDefault();
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
export class FormCheckerReject {
	code:string = null
	fieldPath:string = null
	fieldName:string = null
	fieldValue:string = null
	rejectedContent:string = null
	rejectedParams:any = null
}

const rejectCodeToTextMap = new Map()
rejectCodeToTextMap.set("empty","Champ vide")
rejectCodeToTextMap.set("blank","Champ vide")
rejectCodeToTextMap.set("not-a-number","Le Champ n'est pas un nombre")
rejectCodeToTextMap.set("number-too-great","Le nombre est trop grand")
rejectCodeToTextMap.set("number-too-low","Le nombre est trop grand")

rejectCodeToTextMap.set("invalid-format-dd-mm","Le champ ne respecte pas le format jj/mm")
rejectCodeToTextMap.set("month-not-a-number","Le mois n'est pas un nombre")
rejectCodeToTextMap.set("month-not-int","Le mois n'est pas un nombre entier")
rejectCodeToTextMap.set("month-out-of-bounds","Le mois n'est pas compris entre 1 et 12")
rejectCodeToTextMap.set("day-not-a-number","Le jour n'est pas un nombre")
rejectCodeToTextMap.set("day-not-int","Le jour n'est pas un nombre entier")
rejectCodeToTextMap.set("day-out-of-bounds","Le jour n'est pas compris entre 1 et la fin du moins")
rejectCodeToTextMap.set("array-null","La liste est 'null' (Erreur interne)")
rejectCodeToTextMap.set("not-array","La liste n'en est pas une (Erreur interne)")
rejectCodeToTextMap.set("empty-array","La liste est vide")



export class FormChecker {
	
	
	rejectList:FormCheckerReject[] = []
	data: any
	
	constructor(data:any){
		this.data = data 
	}
	

	
	reject(code:string,fieldPath:string, fieldName:string, rejectedContent:string = null, rejectedParams:any = null){
		let reject = new FormCheckerReject()
		reject.code = code	
		reject.fieldPath = fieldPath
		reject.fieldName = fieldName
		reject.fieldValue = '' 
		let value = this.getValue(fieldPath)
		if(value != null)
			reject.fieldValue = value.toString()
		reject.rejectedContent = rejectedContent
		reject.rejectedParams = rejectedParams
		this.rejectList.push(reject)
		
		//console.log("utils/form-checker.ts:FormChecker:reject()",reject)
	}
	
	isValid(){
		return this.rejectList.length <= 0
	}
	
	formatReject(reject:FormCheckerReject){
		let txt = "Problème inconnu"
		if(rejectCodeToTextMap.has(reject.code)){
			txt = rejectCodeToTextMap.get(reject.code)
		}else{
			txt =  `Problème inconnu (${reject.code})`
		}
		
		return txt
	}

	getValue(fieldPath:string){
		let current = this.data
		let pathSplit = fieldPath.split(/[.]/)
		let pathSplitRewrite = []
		for(let pathComponent of pathSplit){
			let match = pathComponent.match(/(\w+)\[(\d+)\]/)
			if(match==null){
				pathSplitRewrite.push(pathComponent)
				continue
			}
			pathSplitRewrite.push(match[1])
			let index = Number.parseInt(match[2])
			pathSplitRewrite.push(index)
			
		}
		for(let pathComponent of pathSplitRewrite){
			if(current == null)
				return null 
			
			if( typeof current == "undefined")
				return null 
			
			if(pathComponent in current){
				current = current[pathComponent];
			}else{
				console.warn(`utils/form-checker.ts:FormChecker:getValue() : Property ${pathComponent} does not exists in ${fieldPath}`)
				return null
			}
		}
		
		return current
	}
	
	/**
	 * pour checker les string number boolean
	 * si null =>rejet
	 * si string vide => rejet
	 * si string.trim() vide => rejet
	 */
	notBlank(fieldPath:string, fieldName:string ):boolean{
		let value = this.getValue(fieldPath)
		if(value == null){
			this.reject("empty",fieldPath,fieldName)
			return false
		}
		
		if((typeof value === 'string') ){
			if(value.length == 0){
				this.reject("empty",fieldPath,fieldName)
				return false
			}
			if(value.trim().length == 0){
				this.reject("blank",fieldPath,fieldName)
				return false
			}
		}
		
		return true
	}
	
	/**
	 * vérifie jj/mm
	 * ATTENTION!
	 * si null => pas de rejet
	 * si vide => pas de rejet
	 * 
	 * vérifie que mm compris entre 1 et 12
	 * vérifie que le jj compris entre 1 et 29,30,31 selon le mois 
	 */
	dayAndMonth(fieldPath:string, fieldName:string ):boolean{
		let value = this.getValue(fieldPath)
		if(value == null){
			return true
		}
		
		if((typeof value === 'string') && value.trim().length == 0){
			return true
		}
		
		value = value.toString()
		let split = value.split(/\//)
		if(split.length != 2 ){
			this.reject("invalid-format-dd-mm",fieldPath,fieldName,value)
			return false
		}
		let correct = true
		let month = split[1]
		month = Number.parseFloat(month)
		if(Number.isNaN(month)){
			this.reject("month-not-a-number",fieldPath,fieldName,month)
		}else if(!Number.isInteger(month)){
			this.reject("month-not-int",fieldPath,fieldName,month)
			correct = false
		}else if(month <1 || month > 12){
			this.reject("month-out-of-bounds",fieldPath,fieldName,month)
			correct = false
		}
		
		let day = split[0]
		day = Number.parseFloat(day)
		if(Number.isNaN(day)){
			this.reject("day-not-a-number",fieldPath,fieldName,day)
			correct = false
		}else{
			if(!Number.isInteger(day)){
				this.reject("day-not-int",fieldPath,fieldName,day)
				correct = false
			}
			let maxDay = 31 ;
			switch(month){
				case 4:
				case 6:
				case 9:
				case 11:
					maxDay = 30 
					break;
				case 2:
					maxDay = 29 
					break;
				default:
					maxDay = 31
					break;
			}
			// pas de contrôle années bisextiles 
			// 28 ou 29 février = fin févier quelque soit l'année
			// le backend se démerdera
			
			if(day <1 || day > maxDay){
				this.reject("day-out-of-bounds",fieldPath,fieldName,day,{'max':maxDay})
				correct = false
			}
		}
		return correct ;
	}
	
	/**
	 * Vérifie que la valeur est un nombre compris entre min et max
	 * 
	 */
	isBoundNumber(fieldPath:string,min:number, max:number, fieldName:string ):boolean{
		let value = this.getValue(fieldPath)
		if(value == null){
			return true
		}
		
		if((typeof value === 'string') && value.trim().length == 0){
			return true
		}
		
		value = Number.parseFloat(value)
		if(Number.isNaN(value)){
			this.reject("not-a-number",fieldPath,fieldName)
			return false
		}
		
		if( value < min ){
			this.reject("number-too-great",fieldPath,fieldName,value,{min:min})
			return false
		}
		
		if( value > max ){
			this.reject("number-too-low",fieldPath,fieldName,value,{max:max})
			return false
		}
		
		return true
	}
	
	/**
	 * 
	 * 
	 * retourne un ArrayChecker
	 * 
	 */
	arrayChecker(fieldPath:string, fieldName:string):ArrayChecker{
		
		let subChecker =  new ArrayChecker()
		subChecker.formChecker = this
		subChecker.arrayPath = fieldPath
		subChecker.arrayName = fieldName
		return subChecker
	}
	
	
}

export class ArrayChecker {
	formChecker:FormChecker|null = null
	arrayPath:string|null = null
	arrayName:string|null = null
	
	getArray(){
		return this.formChecker.getValue(this.arrayPath)
	}
	
	/**
	 * pour checker qu'un array a des éléments
	 * si null =>rejet
	 * si autre chose qu'un array =>rejet
	 * si tableau vide => rejet
	 * 
	 */
	hasElements(){
		let arr = this.getArray();
		if(arr == null){
			this.formChecker.reject("array-null",this.arrayPath,this.arrayName)
			return false
		}
		
		if( ! Array.isArray(arr) ){
			this.formChecker.reject("not-array",this.arrayPath,this.arrayName)
			return false
		}
		
		if(arr.length <= 0 ){
			this.formChecker.reject("empty-array",this.arrayPath,this.arrayName)
			return false
		}
		
		return true
	}
	
	getElementValue(element: any ,fieldPath:string){
		let current = element
		let pathSplit = fieldPath.split(/[.]/)
		
		for(let pathComponent of pathSplit){
			if(current == null)
				return null 
			
			if( typeof current == "undefined")
				return null 
			
			if(pathComponent in current){
				current = current[pathComponent];
			}else{
				console.warn(`utils/form-checker.ts:FormChecker:getElementValue() : Property ${pathComponent} does not exists in ${fieldPath} in element of array ${this.arrayPath}`)
				return null
			}
		}
		
		return current
	}
	
	
	
	
	/**
	 * vérifie que chaque élément du tableau a un champ qui satisfait notBlank
	 * ATTENTION !
	 * si tableau null => pas de rejet 
	 * si pas un tableau => pas de rejet
	 * si tableau vide => pas de rejet
	 * 
	 * 
	 */
	notBlank(fieldPath:string, fieldName:string){
		let arr = this.getArray();
		if(arr == null){
			return true
		}
		
		if( ! Array.isArray(arr) ){
			return true
		}
		
		if(arr.length <= 0 ){
			return true
		}
		let i = 0
		let failed = false
		for(let element of arr){
			let fullPath = `${this.arrayPath}[${i}].${fieldPath}`
			let fullName = `${this.arrayName} N°${i+1} / ${fieldName}`
			let value = this.getElementValue(element,fieldPath)
			if(value == null){
				this.formChecker.reject("empty",fullPath,fullName)
				failed = true
			}else if((typeof value === 'string') && value.trim().length == 0){
				this.formChecker.reject("blank",fullPath,fullName)
				failed = true
			}
			i++
		}
		return failed
	}
}

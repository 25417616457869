<template>
  <div class="login-container">
    <div class="bloc_content">	
	  <div class="login-banner">Connectez-vous</div>
      <div v-if="status.accountLockMsg" class="login-lock-warning">{{status.accountLockMsg}}</div>
	  
      <form @submit.prevent="login" class="form-horizontal">
        <div class="bloc_form">
          <label for="email">Email</label>
          <input v-model="email" type="text" id="email" class="form-control input_form" placeholder="Email" required>
        </div>
        <div class="bloc_form">
          <label for="password">Mot de passe</label>
          <input v-model="password" type="password" id="password" class="form-control input_form" placeholder="Mot de passe" required>
        </div>
        <div class="form-actions">
          <button type="submit" 
		  	class="btn btn-primary" 
		  	:class="{'disabled': status.connectionInProgress}"
		  	>Login</button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import {authenticationService} from '../services/authentication';

export default {
  data() {
    return {
      email: '',
      password: '',
	  status: authenticationService.status
    };
  },
  methods: {
    async login() {
       await authenticationService.login(this.email, this.password);
    },
  },
};
</script>

<style>
.login-lock-warning{
	margin-top:30px;
	
	background-color:var(--rouge-fonce);
	color:white;
	padding: 10px;
	border-radius: 9px;
}

.disabled {
	&.btn.btn-primary , &.btn-primary.btn:hover, &.btn-primary.btn:focus {
		background-color:var(--fbc-light-gray);
		color:var(--fbc-secondary-text);
		cursor: not-allowed;
	}
}

</style>

<template>
	<table class="user-list">
  		<thead>
  			<tr>
  				<th>Login/email</th>
  				<th>Prénom</th>
  				<th>Nom</th>
				<th>Rôle</th>
  				<th>Mot de passe</th>
  				<th class="user-menu"></th>
  			</tr>
  		</thead>	
  	      <tr class="user-item" 
		  	v-for="user in this.content" 
		  	:key="user.index" 
		  	:class="{'blink':user.blink}" 
			:data-user-email="user.email">
  			<td class="user-field user-email" >{{user.email}}</td>
  			<td class="user-field user-firstname">{{user.firstName}}</td>
  			<td class="user-field user-lastname">{{user.lastName}}</td>
			<td class="user-field user-role" >{{user.roleTitle}}</td>
  			<td class="user-field user-password"><ClearPassword :clearPassword="user.password"/></td>
  			<td class="user-menu grow">
				<input 
					v-if="btnLabel && !user.link"
					type="submit"
					class="btn btn-sm  form-actions"
					:class="[actualBtnColor]" 
					
					:value="btnLabel" 
					@click="btnClick(user)" /> 
				
					<router-link 
						v-if="user.link" 
						:to="user.link" 
						class="btn btn-sm form-actions" 
						:class="[actualBtnColor]">{{linkLabel}}</router-link>
				
			</td>
  		  </tr><!-- class="user-item" -->
  	  </table><!-- class="user-list" -->
</template>
<style>

table.user-list {
	
	th, td { padding:10px; }
	
	tr.user-item:hover { background-color:var(--bleu-clair); }
	
	td:not(.grow), th:not(.grow){ white-space: nowrap; }
	
	td.grow { width: 100%; }
	
	.user-menu {
		text-align:right;
	}
}

.user-list .btn.btn-sm {
	border-radius:5px;
	padding:5px;
	width:auto;
}


.user-item.blink {
  animation: blink-keyframes 1s ease-out 1;
}

@keyframes blink-keyframes {
	from { background-color: var(--rouge); }
 	to { background-color: white; }
}



</style>

<script>
import ClearPassword from './ClearPassword.vue' ;

export default {
	components:{
			ClearPassword
		},
	props:	{
			content:{ 
				type:Array, 
				required:true 
			},
			
			btnLabel:{ 
				type:String, 
				required:false
			},
			linkLabel:{ 
				type:String, 
				required:false
			},
			
			btnClick:{ 
				type:Function, 
				required:false ,
				default: (user)=> console.log("components/UserList.vue:data() / default btnClick()",user)
			},
			
			btnColor:{ 
				type:String, 
				required:false, 
				default:'primary'
			}
	 	},//props:	{
	mounted() {
		//console.log('components/UserList.vue:mounted()',this.btnLabel)
		if(this.btnClick){
			this.actualBtnClick = this.btnClick
		}
		
		if(this.btnColor){
			if(this.btnColor.startsWith("btn-"))
				this.actualBtnColor = this.btnColor
			else
				this.actualBtnColor = 'btn-'+this.btnColor
		}
	},//mounted() {
	data() {
		return { 
			actualBtnColor : null
		 };
	},//data() {
	methods : {
		blinkUser(user){
			
			for(let candidate of this.content){
				
				if(candidate.email !== user.email)
					continue
				
				//console.log('components/UserList.vue:blinkUser()',user,candidate)
				candidate.blink = false
				this.$forceUpdate()
				setTimeout(()=>{
					candidate.blink = true
					this.$forceUpdate()
				},10)
			}
		},//blinkUser(user){
	}//methods : {
}
</script>